import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Web3Actions, Web3Errors, Web3Mutations } from '@/store/enums/Web3Enums';
import { Actions } from '@/store/enums/StoreEnums';
import store from '@/store';
import { Web3Wallet } from '@/types/interfaces/global/Web3Interface';
import Web3Service from '@/core/services/Web3Service';
import { ArtworkMutations } from '@/store/enums/ArtworkEnums';
import sanctum from '@/core/plugins/sanctum';

interface Web3Info {
  wallet: Web3Wallet;
  hasMetamask: boolean;
}

@Module
export default class Web3Module extends VuexModule implements Web3Info {
  wallet = {} as Web3Wallet;
  hasMetamask = true;

  /**
   * Get artists
   * @returns [Array | undefined]
   */
  get getWallet() {
    return this.wallet;
  }

  get hasMetamaskInstalled() {
    return this.hasMetamask;
  }

  @Mutation
  [Web3Mutations.SET_WALLET](wallet) {
    this.wallet = wallet;
  }

  @Mutation
  [Web3Mutations.SET_ADDRESS](address) {
    this.wallet.address = address;
  }

  @Mutation
  [Web3Mutations.SET_ADDRESS_MIN](address) {
    this.wallet.address_min = address;
  }

  @Mutation
  [Web3Mutations.SET_ADDRESS_MICRO](address) {
    this.wallet.address_micro = address;
  }

  @Mutation
  [Web3Mutations.SET_HAS_METAMASK](value) {
    this.hasMetamask = value;
  }

  @Action
  async [Web3Actions.LOAD_WALLET]() {
    const vm = this;
    return Web3Service.currentWallet()
      .then((currentWallet) => {
        if (Object.keys(currentWallet).length === 0) {
          return Web3Errors.WALLET_NOT_SELECTED;
        } else {
          this.context.commit(Web3Mutations.SET_ADDRESS, currentWallet);
          this.context.commit(Web3Mutations.SET_ADDRESS_MIN, currentWallet.substring(3, 39));
          this.context.commit(Web3Mutations.SET_ADDRESS_MICRO, currentWallet.substring(8, 34));
          this.context.commit(Web3Mutations.SET_HAS_METAMASK, true);

          const currentUser = store.getters.currentUser;

          if (currentUser.status !== 'draft') {
            //Guardamos la wallet como metodo de pago si tiene un rol activo.
            // Puede que esté en el proceso de registro todavía
            store.dispatch(Web3Actions.SAVE_CURRENT_WALLET, currentWallet);
          }

          return true;
        }
      })
      .catch(function (error) {
        // console.warn('error', error);
        if (error.message === Web3Errors.METAMASK_NOT_INSTALLED) {
          vm.context.commit(Web3Mutations.SET_HAS_METAMASK, false);
        }

        return error.message;
      });
  }

  @Action
  async [Web3Actions.INIT_METAMASK]() {
    this.context.commit(Web3Mutations.SET_HAS_METAMASK, false);

    return await Web3Service.init()
      .then(() => {
        this.context.commit(Web3Mutations.SET_HAS_METAMASK, true);

        return true;
      })
      .catch(function (error) {
        return error.message;
      });
  }

  @Action
  async [Web3Actions.SAVE_CURRENT_WALLET](wallet) {
    try {
      await sanctum.saveWallet(wallet);
      await store.dispatch(Actions.REFRESH_WALLETS);
    } catch (error: any) {
      console.log(error);
      if (error.response.data) {
        this.context.commit(ArtworkMutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(ArtworkMutations.SET_ERROR, {
          message: 'Error al cargar las obras'
        });
      }
    }
  }
}
