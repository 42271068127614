import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import ArtistModule from '@/store/modules/ArtistModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import CartModule from '@/store/modules/CartModule';
import RegisterModule from '@/store/modules/RegisterModule';
import ArtworkModule from '@/store/modules/ArtworkModule';
import ProfileModule from '@/store/modules/ProfileModule';
// import ProductModule from "@/store/modules/ProductModule";
import Web3Module from '@/store/modules/Web3Module';
import AdminModule from '@/store/modules/AdminModule';

config.rawError = true;

const store = createStore({
  modules: {
    ArtworkModule,
    ArtistModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    CartModule,
    ConfigModule,
    ProfileModule,
    // ProductModule,
    RegisterModule,
    Web3Module,
    AdminModule
  }
});

export default store;
