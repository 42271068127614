enum RegisterActions {
	// action types
	INIT_REGISTER = "initRegister",
}

enum RegisterMutations {
	// mutation types
	RESET_REGISTER = "resetRegister",
	SET_ERROR = "setError",
}

export { RegisterActions, RegisterMutations };
