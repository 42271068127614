export default {
  // Poner los textos que se repiten dentro de este array.
  default: {
    continue: 'Continuar',
    cancel: 'Cancelar',
    subscription: {
      trialing: 'Prueba',
      active: 'Activa',
      incomplete: 'Sin completar',
      incomplete_expired: 'Sin completar - Caducada',
      past_due: 'Caducada',
      canceled: 'Cancelada',
      unpaid: 'Impago',
    }
  },

  Web3: {
    Errors: {
      // Web3Enums -> Web3Errors
      METAMASK_NOT_INSTALLED: 'Por favor, instala la extensión de Metamask',
      NO_BLOCKCHAIN_PROVIDER: 'No hay proveedor de Blockchain',
      WALLET_NOT_SELECTED: 'Necesitamos que inicies sesión en la extensión de Metamask'
    }
  },
  Swal: {
    questions: {
      refresh_page: {
        title: 'Vas a recargar la página',
        text: 'Si continuas puede que pierdas cambios que no hayas guardado.',
        confirm_button: 'Continuar',
        cancel_button: 'Cancelar'
      }
    },
    success: {
      title: '¡Perfecto!',
      text: {
        standard: ''
      },
      confirm_button: {
        standard: 'Continuar'
      }
    }
  },
  Artworks: {
    list: {
      types: {
        all: 'Todos',
        auction: 'Subasta',
        auctionEuro: 'Subasta Euro',
        auctionCrypto: 'Subasta Crypto',
        directSell: 'Venta directa',
        directSellMixed: 'Venta mixta',
        directSellEuro: 'Venta directa Euro',
        directSellCrypto: 'Venta directa Crypto',
        notForSale: 'No en venta'
      }
    }
  },
  ErrorHandler: {
    Auth: {
      Login: {
        AccesDenied: 'No puedes realizar esta acción',
        RegistrationIncomplete: 'Debes terminar el proceso de registro',
        AccountNotVerified: 'Cuenta no verificada',
        Unauthenticated: 'Usuario no identificado',
        ItemNotFound: 'No hay resutlados',
        InvalidRoute: 'Ruta inválida',
        Unknown: 'Desconocido',
        GeneralError: 'Ha habido un error, revisa los datos'
      },
      Register: {
        AccesDenied: 'No puedes realizar esta acción',
        RegistrationIncomplete: 'Debes terminar el proceso de registro',
        AccountNotVerified: 'Cuenta no verificada',
        Unauthenticated: 'Usuario no identificado',
        ItemNotFound: 'No hay resutlados',
        InvalidRoute: 'Ruta inválida',
        Unknown: 'Desconocido',
        GeneralError: 'Ha habido un error, revisa los datos'
      }
    }
  },
  Customers: {
    Invoices: 'Facturas',
    OrderId: 'Nº Factura',
    Amount: 'Cantidad',
    Status: 'Estado',
    Date: 'fecha',
    Invoice: 'Factura',
    PaymentMethods: 'Métodos de Pago',
    Primary: 'Predeterminado',
    ChargeMethods: 'Métodos de cobro',
    SetPrimary: 'Marcar como predeterminada',
    PaymentRecords: 'Compras/Ventas',
    Artworks: 'Listado de obras',
    AttachedDocs: 'Docs Adjuntos',
    Collections: 'Colecciones'
  },

  auth: {
    error: {
      title: 'Error',
      text: 'Ha ocurrido un error, revisa los datos.',
      ok: 'Aceptar'
    },
    pending: {
      title: 'Cuenta pendiente de confirmar',
      text: 'Nos pondremos en contacto cuando activemos las funcionalidades de tu cuenta',
      ok: 'Entendido',
      form_action: 'Detalle de Artista'
    },
    register: {
      stepper: {
        step_3: {
          item_1 :{

          },
          item_2 :{

          },
          item_3 :{

          },
        },
      },
      completed_title: '¡Enhorabuena, Has completado el registro inicial!',
      completed_description: 'A continuación te daremos información más detallada del proceso de onboarding.',
      completed_button: 'Continuar'
    },
    typebilling: {
      business: 'Empresa',
      freelance: 'Autónomo'
    },
    billingAddress_nif: 'N.I.F.',
    billingAddress_cif: 'C.I.F.',
    billingAddress_business_name: 'Nombre de empresa',
    billingAddress_city: 'Ciudad',
    billingAddress_cp: 'Código Postal',
    billingAddress_region: 'Provincia',
    billingAddress_country: 'Pais',
    billingAddress_address: 'Dirección',
    billingAddress_phone: 'Teléfono de contacto',
    billingAddress_surname: 'Apellidos',
    billingAddress_name: 'Nombre',
    billing_info: 'Dirección de facturación',
    shippingAddress_city: 'Ciudad',
    shippingAddress_cp: 'Código Postal',
    shippingAddress_region: 'Provincia',
    shippingAddress_country: 'Pais',
    shippingAddress_address: 'Dirección',
    shippingAddress_aditional_info: 'Información adicional',
    shippingAddress_phone: 'Teléfono de contacto',
    shippingAddress_name: 'Nombre descriptivo',
    shipping_info: 'Dirección de envío',
    MuseumAccount: 'Gestión de Colecciones',
    MuseumsDescription: 'Museos, Galerias, Coleccionistas, etc',
    pendiente: 'Estimado <strong>%{valor1}</strong>, hemos recibido su información correctamente.',
    createAccount: 'Crear una cuenta',
    newHere: '¿Eres nuevo?',
    userAccount: 'Cuenta de usuario <strong>%{valor1}</strong> y clave <strong>qwerty123456</strong> para entrar.',
    Email: 'Correo electrónico',
    Password: 'Contraseña',
    forgotPassword: '¿Olvidaste la contraseña?',
    Continue: 'Continuar',
    or: 'O',
    pleaseWait: ' Por favor espera...',
    continueWithPlat: 'Entrar con %{plat}',
    ChooseAccountType: 'Elija el tipo de cuenta',
    NeedMoreInfo: 'Si necesitass más información, consulta',
    AlreadyAccount: '¿Ya tienes una cuenta?',
    SignInHere: 'Entra aquí',
    FirstName: 'Nombre',
    LastName: 'Apellido',
    ConfirmPassword: 'Confirmar contraseña',
    UseNumbersSymbols: 'Utilice 8 o más caracteres con una combinación de letras, números y símbolos',
    IAgree: 'Acepto los ',
    TermsConditions: 'términos y condiciones',
    Submit: 'Entrar',
    NameRequired: 'El nombre es un campo obligatorio',
    SurnameRequired: 'El apellido es un campo obligatorio',
    EmailRequired: 'El correo electrónico es un campo obligatorio',
    PasswordRequired: 'La contraseña es un campo obligatorio',
    PasswordConfirmationRequired: 'La confirmación de la contraseña es un campo obligatorio',
    PassConditions: 'Use más de 8 dígitos mezclando letras, números y símbolos',
    minchar: 'La contraseña debe tener mínimo 4 caracteres',
    EmailBeValid: 'Este campo debe ser un correo electrónico válido',
    PasswordsMustMatch: 'Las contraseñas deben coincidir',
    PasswordLeastCharacters: 'La contraseña debe tener al menos 4 caracteres',
    CredentialsIncorrect: 'Las credenciales proporcionadas son incorrectas',
    TryAgain: '¡Intentar otra vez!',
    SuccessfullyLogged: '¡Has iniciado sesión correctamente!',
    GotIt: '¡Ok lo tengo!',
    ForgotPassword: '¿Has olvidado tu contraseña?',
    ResetPassword: 'Ingrese su correo electrónico para restablecer su contraseña',
    Cancel: 'Cancelar',
    LogoReg: 'media/logos/logo-reg.svg',
    AccountType: 'Tipo de cuenta',
    SetupAccountRol: 'Configurar su rol de cuenta',
    FunctionPlans: 'Función y planes ',
    HowWillAct: 'Elige cómo actuarás en nuestra plataforma',
    Details: 'Detalles',
    YourInfo: 'Su información relacionada',
    PayMethods: 'Métodos de pago',
    SetMethods: 'Establezca sus métodos de pago',
    PayMethodsAndSuscription: 'Pago y suscripción',
    SetPayMethodsAndSuscription: 'Establezca el método de pago y la suscripción',
    Completed: 'Completado',
    WeAreHere: 'Woah, estamos aquí',
    ChooseAccounttype: 'Elija el tipo de cuenta',
    MoreInfoCheckOut: 'Si necesita más información, consulte',
    HelpPage: 'Página de ayuda',
    BuyerAccount: 'Cuenta de Comprador',
    TradeWithCrypto: 'Perfil para comprar obras en Auth4art',
    ArtAccount: 'Cuenta de Artista',
    ArtistOrDealers: 'Perfil de autor que vende sus obras',
    ManagerAccount: 'Gestor',
    ManagerAccountDesription: 'Museos, coleccionistas, ...',
    AccountInfo: 'Información de la cuenta',
    SpecifyAccountFunction: 'Especificar función de cuenta',
    Artist: 'Artista',
    Dealer: 'Marchante',
    Gallery: 'Galería',
    Museum: 'Museo',
    CustomersDescriptor: 'Los clientes verán esta versión abreviada de su descripción de estado de cuenta',
    AccountName: 'Nombre de la cuenta',
    AccountNameRequired: 'El nombre de la cuenta es un campo obligatorio',
    SelectAccountPlan: 'Seleccionar plan de cuenta',
    FREE: 'FREE ',
    StartUsingPlatform: 'Para empezar a usar nuestra plataforma',
    EnterLevelBenefits: 'Ingrese a un nuevo nivel de beneficios',
    ForDemandingCustomers: 'Para las clientes más exigentes.',
    AccountDetails: 'Detalles de la cuenta',
    Name: 'Nombre',
    Surname: 'Apellido',
    Phone: 'Teléfono',
    ContactEmail: 'Email de contacto',
    BillingDetails: 'Detalles de facturación',
    NameOnCard: 'Nombre en la tarjeta',
    CardNumber: 'Número de tarjeta',
    ExpirationDate: 'Fecha de caducidad',
    SaveCard: '¿Guardar tarjeta para facturar más?',
    MoreInfoBudgetPlanning: 'Si necesita más información, consulte la planificación presupuestaria',
    SaveCardOnly: 'Guardar tarjeta',
    YourAreDone: 'Lo has conseguido!',
    WritingHeadlinesForBlog:
      'Ya puedes comenzar a subir tus obras y registrarlas en Blockchain. Un nuevo mundo te espera!',
    NeedAttention: '¡Necesitamos su atención!',
    ToStartGreatTools: 'Para comenzar a usar Auth4Art, por favor',
    CreateTeamPlatform: 'Acceda por primera vez',
    Address: 'Dirección',
    AddressName: 'Nombre de dirección',
    AddressNamePlaceholder: 'Casa',
    Postal: 'Código Postal',
    City: 'Ciudad',
    Country: 'País',
    Region: 'Región',
    logout: {
      action: 'Cerrar sesión',
      alert: {
        title: '¿Estás seguro que quieres cerrar sesión?',
        text: 'Los cambios que no hayas guardado se perderán',
        confirm: 'Sí, cerrar sesión',
        cancel: 'Cancelar'
      }
    }
  },
  footer: {
    About: '',
    Contact: '',
    ContactUs: ''
  },
  siginto: 'Entrar a',
  dashboard: 'Inicio',
  layoutBuilder: 'Constructor de maquetación',
  craft: 'Obras',
  pages: 'Paginas',
  profile: 'Perfil',
  profileOverview: 'Descripción general',
  projects: 'Proyectos',
  campaigns: 'Campañas',
  documents: 'Documentos',
  connections: 'Conexiones',
  wizards: 'Magos',
  horizontal: 'Horizontal',
  vertical: 'Vertical',
  account: 'Cuenta',
  accountOverview: 'Descripción general',
  settings: 'Ajustes',
  authentication: 'Autenticación',
  basicFlow: 'Flujo básico',
  signIn: 'Registrarse',
  signUp: 'Inscribirse',
  passwordReset: 'Restablecimiento de contraseña',
  multiStepSignUp: 'Regístrese Multi-Pasos',
  error404: 'Error 404',
  error500: 'Error 500',
  apps: 'Artistas',
  chat: 'Chat',
  privateChat: 'Chat privado',
  groupChat: 'Grupo de chat',
  drawerChat: 'Chat del cajón',
  widgets: 'Widgets',
  widgetsLists: 'Liza',
  widgetsStatistics: 'Estadísticas',
  widgetsCharts: 'Gráficos',
  widgetsMixed: 'ArtDevices',
  widgetsTables: 'Mesas',
  widgetsFeeds: 'Alimenta',
  changelog: 'Registro de cambios',
  docsAndComponents: 'Documentos & Componentes',
  megaMenu: 'Mega menú',
  exampleLink: 'Enlace de ejemplo',
  modals: 'Modales',
  general: 'General',
  inviteFriends: 'Invitar A Amigos',
  viewUsers: 'Ver Usuarios',
  upgradePlan: 'Plan De Actualización',
  shareAndEarn: 'Compartir Y Ganar',
  forms: 'Formas',
  newTarget: 'Nuevo Objetivo',
  newCard: 'Nueva Tarjeta',
  newAddress: 'Nueva Direccion',
  createAPIKey: 'Crea Clave De Api',
  twoFactorAuth: 'Dos Factores',
  createApp: 'Crear Aplicacion',
  createAccount: 'Crear Una Cuenta',
  activity: 'Actividad',
  documentation: 'Documentación',
  resources: 'Servicios',
  customers: 'Clientes',
  gettingStarted: 'Empezando',
  customersListing: 'Listado De Clientes',
  customerDetails: 'Detalles De Los Clientes',
  calendarApp: 'Calendario',
  subscriptions: 'Suscripciones',
  getStarted: 'Empezando',
  subscriptionList: 'Lista De Suscripción',
  addSubscription: 'Añadir Suscripción',
  viewSubscription: 'Suscripción',
  Download: 'Descarga',

  trans: {
    contact: {
      required: {
        subject: 'Introduce el motivo del mensaje',
        message: 'Danos más información y nos pondremos en contacto lo antes posible.'
      }
    },
    register: {
      pending: {
        title: 'Estamos analizando tu documentación',
        subtitle: '¡Muchas gracias por tu interés!',
        info: 'Hemos recibido tu información y estamos tramitando tu solicitud.',
        info_received: 'Hemos recibido tu información y estamos tramitando tu solicitud.',
        contact:
          'Lo revisaremos lo antes posible y te comunicaremos si has sido aceptado para formar parte de nuestra comunidad y comenzar a utilizar el ecositema.'
      },
      pending_email: {
        title: 'Verificar email',
        info: 'Para poder continuar tienes que verificar tu email, si no has recibido el email de confirmación haz clic en "Reenviar email de confirmación"',
        verify_email: {
          button: 'Reenviar email de confirmación',
          success_title: 'Email enviado',
          success_description: 'El email de confirmación se ha enviado correctamente'
        }
      },
      required: {
        nameOnCard: 'El nombre de tarjeta es obligatorio',
        roleable: 'Es necesario que elijas un tipo de cuenta',
        name: 'Por favor, introduce tu nombre',
        name2: 'Por favor, introduce un nombre',
        icon: 'Por favor, escoge un icono',
        surname: 'Por favor, introduce tus apellidos',
        phone: 'Por favor, introduce tu teléfono',
        contact_email: 'Por favor, introduce un correo de contacto',

        shippingAddress_name: 'Por favor, introduce un nombre',
        shippingAddress_address: 'Por favor, introduce una dirección',
        shippingAddress_country: 'Por favor, selecciona un país',
        shippingAddress_phone: 'Por favor, un teléfono de contacto',
        shippingAddress_region: 'Por favor, selecciona una província',
        shippingAddress_cp: 'Por favor, introduce un código postal',
        shippingAddress_city: 'Por favor, introduce una ciudad',

        billingAddress_business_name: 'Por favor, introduce el nombre de la sociedad',
        billingAddress_name: 'Por favor, indica tu nombre',
        billingAddress_surname: 'Por favor, introduce tus apellidos',
        billingAddress_address: 'Por favor, introduce una dirección',
        billingAddress_nif: 'Por favor, indica un documento legal',
        billingAddress_phone: 'Por favor, un teléfono de contacto',
        billingAddress_country: 'Por favor, selecciona un país',
        billingAddress_region: 'Por favor, selecciona una región',
        billingAddress_city: 'Por favor, introduce una ciudad',
        billingAddress_cp: 'Por favor, introduce un código postal',
        billingAddress_type: 'Por favor, selecciona una opción',

        paymentMethod_name: 'Por favor, introduce el nombre que aparece en la tarjeta'
      },
      regex: {
        phone: 'Por favor, introduce un teléfono válido',
        shippingAddress_cp: 'Por favor, introduce un código postal válido',
        billingAddress_nif: 'Por favor, introduce un documento válido',
        billingAddress_cp: 'Por favor, introduce un código postal válido'
      },
      email: {
        contact_email: 'Por favor, introduce un correo válido'
      },
      test: {
        shippingAddress_cp: 'El código postal no coincide con la provincia',
        billingAddress_cp: 'El código postal no coincide con la provincia'
      }
    },
    saveArtwork: {
      swal: {
        title1:"Creando Obra",
        title2:"Obra creada",
        title3:"Crear",
        text1:"¿Estás seguro que quieres continuar?",
        text2:"Se están procesando los datos de la obra",
        text3:"La obra se ha creado pero todavía no es pública: El autor todavía no está validado",
        text4:"No se ha podido crear la obra, por favor revisa los datos o contacta con soporte.",
        text5:"Muchas gracias por confiar en nosotros",
        confirmButtonText1:"Sí, crear obra",
        confirmButtonText2:"Entendido",
        cancelButtonText1:"No",
        error:"Error",
      },
      required: {
        title: 'Por favor, introduce un título',
        author: 'Por favor, selecciona un autor',
        caption: 'Por favor, introduce una descripción corta',
        description: 'Por favor, introduce una descripción',
        frontImage: 'Por favor, selecciona una imagen de portada',
        profileImage: 'Por favor, selecciona una imagen de perfil',
        discipline: 'Por favor, escoge una disciplina',
        condition: 'Por favor, escoge una condition',
        type: 'Por favor, escoge un tipo',
        is_nft: 'Por favor, escoge Obra física o NFT',
        nftFile: 'Por favor, selecciona un archivo',
        xFile: 'Por favor, selecciona un archivo',
        saleTypes: 'Por favor, selecciona un tipo de venta',
        startPrice: 'Por favor, introduce un precio',
        startDate: 'Selecciona una fecha de inicio',
        endDate: 'Selecciona una fecha de fin',
      }
    },
    role: {
      public_profile: {
        swal_change_visibility_to_public: {
          title: '¿Estás seguro que quieres publicar tu perfil?',
          text: 'De esta forma podrás publicar y vender tus obras. Las obras ya creadas deberás publicarlas de forma manual.',
          confirm_button: 'Sí, publicar mi perfil',
          cancel_button: 'Cancelar'
        },
        swal_change_visibility_to_private: {
          title: '¿Estás seguro que quieres hacer tu perfil privado?',
          text: 'No podrás ni publicar ninguna obra. Debes asegurarte de que no tengas obras en venta ni subasta para poder continuar.',
          confirm_button: 'Sí, hacer mi perfil privado',
          cancel_button: 'Cancelar'
        },
        swal_change_visibility_changed: {
          title: '¡Éxito!',
          text: 'Visibilidad modificada',
          confirm_button: 'Entendido'
        }
      }
    },
    artwork: {
      list: {
        artwork:'OBRAS',
        artwork_:'Obras',
        artwork_list:'Listado de obras',
        own_artwork:'Obras propias',
        sell_artwork:'Obras en venta',
        auction_artwork:'Obras en subasta',
        create_collection:'Crear colección',
        create_artwork:'Crear Obra',
        auction:"Subasta",
        sale:"Venta",
        unsigned:"Sin firmar",
        signed:"Firmado",
        private:"Privado",
        public:"Público",
        nft:"NFT",
        physical:"Física",
        s3_xfile:"Archivo",
      },
      create_wizard: {
        step_content: {
          return:"Volver",
          title1:"Título y Autor",
          title2:"Información de la obra",
          title3:"Venta directa o subasta",
          title4:"Resumen",
          finish:"Terminar",
          loading:"Cargando...",
          continue:"Continuar",
        },
        step1: {
          start:"Empecemos",
          title:"Título",
          select_author:"Selecciona una autor",
          more_authors:"autores más...",
          added_authors:"Añadir autor",
          not_found_authors:"autores más...",
        },
        step2: {
          title: "Tipo de obra",
          drag_or_click:"Suelte el archivo aquí o haga clic para cargar.",
          drag_or_click_2:"Cargar archivo",
          file_limit:"El archivo es demasiado grande.",
          file_limit_25:"Tamaño máximo permitido es de 25 MB",
          img_home:"Imagen portada",
          img_profil:"Imagen Perfil",
          x_files:"Carga un archivo: Vídeo (mp4), Música (mp3,wav), Documentos (pdf), Plano, 3D",
          description:"Descripción",
          discipline:"Disciplina",
          tech:"Técnica",
          sign_author:"Firmada por el autor",
          choice_1:"Formato imagen",
          choice_1_help:"Archivo .png, .jpg",
          choice_2:"Formato media",
          choice_2_help:"Archivo de video, audio, pdf",
          physical_work:"Obra física",
          digital_work:"Obra digital (NFT)",
          short_description:"Desc. Corta",
          file_token:"Archivo a tokenizar",
          activate_editions:"Activar ediciones",
          automatically_generates_nft:"Genera automáticamente una cantidad de copias en formato NFT.",
          edition:"Edición",
          signed_author:"Firmada por el autor",
          amount:"Cantidad",
          create_nft:"Crear NFT",
          generate_nft:"Genera el NFT al finalizar el proceso de creación.",
          create:"Crear",
          wallets:"Wallets",
          swal: {
            confirmButtonText1: 'Entendido',
            text1: 'Ha habido un error con Metamask'
          },
          notice: {
            digital: {
              title: 'NFT',
              text:
                'La obra es un NFT y no podrá tener ningún dispositivo físico vinculado. ' +
                'Una vez creada en Blockchain, la obra no se podrá modificar.'
            },
            physical: {
              title: 'Obra física',
              text: 'La obra podrá tener dispositivos vinculados físicamente. Podrás crear un NFT de esta obra en cualquier momento.'
            }
          },
          public: {
            label: 'Obra pública',
            label_text: 'La obra será visible en la plataforma',
            radio_text: 'Pública',
            description_on_sell: 'Si pones la obra en venta, se publicará automáticamente',
            description_on_profile_not_public: 'Debes activar tu cuenta antes de poder publicar o vender obras'
          }
        },
        step3: {
          cannot_be_put_up_for_sale:'Esta obra no se puede poner en venta',
          check_the_create_nft:'Al ser un NFT, debes marcar la opción \'Crear NFT\' si la quieres vender. De otra forma, el NFT como tal, no se puede vender ya que no existirá hasta que se cree en Blockchain.',
          sale_or_auction:'Venta o Subasta',
          sell:'Vender',
          check_sale: {
            label: 'Activar la venta directa o subasta',
            label_text: 'Puedes vender tu obra en cualquier momento después de crearla'
          },
          swal_is_public: {
            title: 'La obra es privada',
            text: 'Estás intentando vender una obra privada, si continuas se publicará la obra',
            button_confirm: 'Sí, hacer la obra pública',
            button_cancel: 'No, la pondré en venta más adelante'
          }
        },
        step4: {
          summary_of_the_work:'Resumen de la obra',
          you_can_get_an_idea:'Esta es el resumen de los datos que has introducido en la obra, aquí podrás hacerte una idea de cómo se verá tu obra en www.auth4art.com.',
          not_be_possible_to_edit:'No será posible editar la información de la obra si está en Blockchain',
          when_you_finish_creating:'Cuando finalices la creación de la obra, ésta estará en Blockchain y ,para preservar la consistencia de datos, no se podrá modificar la obra en un futuro. Pero podrás ponerla en venta y/o en subasta en cualquier momento.',
          cannot_be_put_up_for_sale:'Esta obra no se puede poner en venta',
          being_an_nft:'Al ser un NFT, debes marcar la opción \'Crear NFT\' si la quieres vender. De otra forma, el NFT como tal, no se puede vender ya que no existirá hasta que se cree en Blockchain.',
          cover_image:'Imagen de portada',
        }
      },
      edit_artwork: {
        title: 'Editando Obra',
        form: {
          title: 'Título',
          author: 'Autor',
          caption: 'Descripción Corta',
          description: 'Descripción',
          artwork_discipline: 'Disciplina',
          artwork_type: 'Tipo',
          artwork_signed: 'Firmado por el autor',
          buttons: {
            cancel: 'Cancelar',
            save: 'Guardar',
            loading: 'Por favor, espera'
          },
          required: {
            title: 'Por favor, introduce un título',
            caption: 'Por favor, introduce una descripción corta',
            description: 'Por favor, introduce una descripción',
            artwork_discipline: 'Por favor, escoge una disciplina',
            condition: 'Por favor, escoge una condition',
            artwork_type: 'Por favor, escoge un tipo',
            edition_quantity: 'Por favor, introduce una cantidad',
          }
        },
        swal: {
          confirm: {
            title: 'Modificar obra',
            text: '¿Estás seguro que quieres continuar?',
            button_confirm: 'Sí, continuar',
            button_cancel: 'No, cancelar'
          },
          loading: {
            title: 'Modificando obra',
            text: 'Esta acción puede tardar unos segundos'
          },
          success: {
            title: 'Éxito',
            text: 'Obra modificada',
            button_confirm: 'Entendido'
          },
          error: {
            title: 'Error',
            text: 'Ha habido un error al guardar los datos',
            button_confirm: 'Entendido'
          },
          title: 'La obra es privada',
          text: 'Estás intentando vender una obra privada, si continuas se publicará la obra',
          button_confirm: 'Sí, hacer la obra pública',
          button_cancel: 'No, la pondré en venta más adelante'
        }
      },
      detail_artwork: {
        does_not_support: 'Your browser does not support the video tag.',
        title_media: 'Media',
        loading: 'Cargando...',
        title: 'Editando Obra',
        return:"Volver",
        edit:"Editar",
        public:"Pública",
        private:"Privada",
        characteristics:"Características",
        nft:"NFT",
        physical:"Física",
        signed:"Firmado",
        auction:"Subasta",
        understood:"Entendido",
        sale:"Venta",
        artist:"Artista",
        discipline:"Disciplina",
        type:"Tipo",
        general_view:"Vista General",
        devices:"Dispositivos",
        description:"Descripción",
        link_art_device:"Vincular Art Device",
        link_smart_tag:"Vincular Smart Tag",
        create_direct_sale:"Crear venta directa o subasta",
        sale_is_queued:"La venta está en cola para ser procesada",
        this_may_take_few_minutes:"Esto puede tardar unos minutos, cuando termine se  pondrá a la venta automáticamente",
        sell_matic_and_euro:"Puedes vender en MATIC y EURO o subastar en MATIC o EURO",
        nfts_in_the_making:"NFT en proceso de creación",
        the_work_is_an_nft:"La obra es un NFT y no está creada en Blockchain. Para poder venderla tiene que estar registrada",
        functionality_is_not_yet_available:"Esta funcionalidad aún no está disponible",
        cannot_be_put_up_for_sale:"La obra no se puede poner en venta",
        is_not_created_on_the_blockchain:"La obra es un NFT y no está creada en Blockchain. Para poder venderla tiene que estar registrada",
        change_the_status:"Cambiar el estado de la obra",
        this_work_will_be_private:"Esta obra será Privado",
        this_work_will_be_public:"Esta obra será Pública",
        work_cannot_be_public:"La obra no puede ser pública",

        swal: {
          toggle_private_on_sale: {
            title: 'La obra está en venta',
            text: 'Si continuas, cancelarás las ventas actuales. En caso de subasta, no se podrá cancelar si ya hay pujas activas.',
            button_confirm: 'Sí, continuar',
            button_cancel: 'No, cancelar'
          },
          toggle_visibility_success: {
            title: 'Éxito',
            text: 'Visibilidad modificada',
            button_confirm: 'Entendido'
          },
          toggle_visibility_error: {
            title: 'Error',
            text: 'No se ha podido modificar la visibilidad de la obra',
            button_confirm: 'Entendido'
          }
        }
      }
    }
  },
  ecommerce: {
    product: {
      subscripcion: {
        title: 'Licencias',
        description:
          'Con los planes Auth4Art entrarás en el mundo del arte digital sin riesgos y con una facilidad nunca vista'
      },
      iot: {
        title: 'IoT',
        description:
          'Monitoriza tus obras en tiempo real y garantiza: La autoría, autenticidad y Pertenencia de cada obra'
      },
      etiqueta: {
        title: 'Smart Tags',
        description: 'Registra tus obras con información concreta de la obra y documentos adjuntos.'
      },
      monthly: 'Mensual',
      first_month_included: 'Primera mensualidad incluida',
      artworks_limit: 'Límite de obras'
    },
    buttons: {
      addToCart: 'Añadir',
      details: 'Detalles'
    },
    cart: {
      empty_cart_message: 'Todavía no tienes ningún producto en el carrito',
      summary: 'Resumen',
      quantity: 'Cantidad',
      total: 'Total',
      taxes: 'Impuestos',
      amount: 'Precio',
      subscription: 'Suscripción',
      process: 'Tramitar',
      complete_order: 'Pagar',
      next: 'Siguiente',
      total_unique: 'Pago único',
      total_subscription: 'Suscripción',
      shipping: 'Envío'
    },
    checkout: {
      validation: {
        required: {
          shipping_address: 'Tienes que seleccionar una dirección de envío',
          billing_address: 'Tienes que seleccionar una dirección de facturación',
          payment_method: 'Tienes que seleccionar un método de pago'
        }
      },
      buttons: {
        new_payment_method: 'Nuevo método de pago',
        new_address: 'Nueva dirección',
        edit: 'Editar'
      },
      payment_method: 'Método de pago',
      credit_card: 'Tarjeta',
      default: 'Por defecto',
      shipping_data: 'Dirección de envío',
      details: 'Detalles',
      address: 'Dirección',
      postal_code: 'Código postal',
      city: 'Ciudad',
      province: 'Provincia',
      country: 'País',
      billing_data: 'Dirección de facturación'
    },
    order: {
      order_detail: 'Detalle pedido',
      order_status: 'Estado del pedido',
      payment_method: 'Método de pago',
      shipping_address: 'Dirección de envío',
      product: 'Producto',
      quantity: 'Cantidad',
      unit_price: 'Precio por unidad',
      price: 'Precio',
      subtotal: 'Subtotal',
      taxes: 'Impuestos',
      subscription: 'Suscripción',
      shipping_costs: 'Gastos de envío',
      total: 'Total',
      empty_datatable: 'Todavía no tienes ningún pedido',
      status: {
        created: 'Creado',
        paid: 'Pagado',
        payment_failed: 'Pago fallido',
        shipping: 'Enviado',
        closed: 'Cerrado',
        cancelled: 'Cancelado',
      }
    }
  },
  account_profile: {
    account_details: 'Detalles de la cuenta',
    artworks: 'Obras',
    notices: 'Avisos',
    roles: 'Roles',
    validation: {
      required: {
        current_password: 'Escribe tu contraseña actual'
      },
      password_too_short: 'Tu contraseña es muy corta',
      password_not_match: 'Confirmar contraseña no coincide con tu nueva contraseña'
    },
    forms: {
      email: 'Email',
      fullname: 'Nombre completo',
      current_password: 'Contraseña actual',
      new_password: 'Nueva contraseña',
      confirm_password: 'Confirmar contraseña',
      data_updated: 'Tus datos has sido actualizados correctamente'
    },
    buttons: {
      save: 'Guardar',
      manage: 'Administrar',
      understood: 'Entendido'
    }
  },
  form_request: {
    default_error: {
      title: 'Error',
      description: 'Se ha producido un error inesperado'
    }
  },
  breadcrumbs: {
    process_order: 'Tramitar pedido',
    products: 'Productos',
    my_orders: 'My orders',
    my_profile: 'Mi perfil'
  },
  components: {

  },
  modules:{
    authentication: {
      Item3MetamaskNetwork: {
        title: 'Red de Polygon',
        faq1: {
          title: '¿Cómo añado una red con la extensión de Metamask?',
          description: {
            item1: 'En la parte superior izquierda, encontrarás un desplegable con todas tus redes',
            item2: 'Puedes añadir una red en cualquier momento haciendo click en <strong>Agregar red</strong>',
            item3: 'En este punto se abrirá una pestaña nueva hacía la extensión de Metamask, verás todas las redes que tienes y podrás buscar la red que quieras añadir',
            item4: 'En nuestro caso, vamos a buscar <strong>Polygon Mainnet</strong> y le daremos a <strong>Guardar</strong>.',
            item5: '¡Listo! Ya tienes la red principal de Polygon habilitada en tu Metamask',
          },
        },
        network_details: {
          network: {
            title: 'Network Name',
            description: 'Polygon',
          },
          rpc_url: {
            title: 'New RPC URL',
            description: 'https://polygon-rpc.com',
          },
          chain_id: {
            title: 'ChainID',
            description: '137',
          },
          symbol: {
            title: 'Symbol',
            description: 'MATIC',
          },
          explorer_url: {
            title: 'Block Explorer URL',
            description: 'https://polygonscan.com',
          },
        },
      },
    },
  },
  composables: {
    subscription: {
      cancel_customer_subscription: {
        title: '¿Estás seguro que quieres cancelar tu suscripción?',
        description: 'Se te asignará automáticamente el plan gratuito, para cancelar tu cuenta ponte en contacto con soporte'
      },
      subscription_customer_canceled: {
        title: 'Suscripción cancelada',
        description: 'Puedes seguir usando la plataforma con las limitaciones del plan gratuito.'
      },
      cancel_device_subscription: {
        title: '¿Estás seguro que quieres cancelar tu suscripción?',
        description: 'No podrás visualizar la información del dispositivo'
      },
      subscription_device_canceled: {
        title: 'Suscripción cancelada',
        description: 'Puedes re activar el dispositivo cuando quieras'
      },
      subscription_device_reactivate: {
        title: '¿Estás seguro que quieres reactivar el dispositivo?',
        description: 'Usaremos tu método de pago por defecto para realizar el primer cobro.'
      },
      subscription_device_reactivated: {
        title: 'Dispositivo activado',
        description: 'Ya puedes visualizar los datos de tu dispositivo'
      },
    }
  }
};
