import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from '@/core/plugins/axios';
import store from '@/store';

import {
  BillingAddressRequest,
  Country,
  PaymentMethodRequest,
  ShippingAddressRequest,
  SectionData,
  TotalSoldData
} from '@/types/interfaces/global/UserInterface';
import sanctum from '@/core/plugins/sanctum';

@Module
export default class ProfileModule extends VuexModule {
  errors = {};
  shippingDataRequest = {} as ShippingAddressRequest;
  billingDataRequest = {} as BillingAddressRequest;
  paymentDataRequest = {} as PaymentMethodRequest;
  countries = [] as Country[];
  roleSectionData = {} as SectionData | undefined;
  totalSoldData = {} as TotalSoldData | undefined;

  get getProfileErrors() {
    return this.errors;
  }

  get getCountries() {
    return this.countries;
  }

  get getShippingDataRequest() {
    return this.shippingDataRequest;
  }

  get getbillingDataRequest() {
    return this.billingDataRequest;
  }

  get getPaymentMethodDataRequest() {
    return this.paymentDataRequest;
  }

  get getRoleSectionData() {
    return this.roleSectionData;
  }

  get getTotalSoldData() {
    return this.totalSoldData;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_COUNTRIES](countries) {
    this.countries = countries;
  }

  @Mutation
  [Mutations.SET_SECTION_DATA](data) {
    this.roleSectionData = data;
  }

  @Mutation
  [Mutations.SET_TOTAL_SOLD_DATA](data) {
    this.totalSoldData = data;
  }

  @Mutation
  [Mutations.SET_SHIPPING_DATA_REQUEST](shippingData) {
    this.shippingDataRequest = shippingData;
  }

  @Mutation
  [Mutations.SET_BILLING_DATA_REQUEST](billingData) {
    this.billingDataRequest = billingData;
  }

  @Mutation
  [Mutations.SET_PAYMENT_METHOD_DATA_REQUEST](paymentDataRequestData) {
    this.paymentDataRequest = paymentDataRequestData;
  }

  @Action
  async [Actions.LOAD_COUNTRIES]() {
    try {
      const countries = await axios.get('/public/country');
      store.commit(Mutations.SET_COUNTRIES, countries.data.data);
    } catch (error: any) {
      console.log(error);
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Countries error'
        });
      }
    }
  }

  @Action
  async [Actions.UPDATE_PROFILE](data) {
    try {
      console.log(data);
      await axios.post('/private/profile/update', data);
      this.context.commit(Mutations.SET_ERROR, {});
    } catch (error: any) {
      console.log(error);
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Profile error'
        });
      }
    }
  }

  @Action
  async [Actions.UPDATE_PUBLIC_PROFILE](data) {
    try {
      const currentRole = store.getters.currentRole;
      await axios.post(`/private/role/${currentRole.hash}/profile/update-public`, data);
      this.context.commit(Mutations.SET_ERROR, {});
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Public profile error'
        });
      }
    }
  }

  @Action
  async [Actions.CHANGE_ROLE_PUBLIC_PROFILE_VISIBILITY]() {
    try {
      const currentRole = store.getters.currentRole;
      await axios.post(`/private/role/${currentRole.hash}/profile/change-public-visibility`);

      return true;
    } catch (error: any) {
      if (error.response.data) {
        return error.response.data.message;
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Public profile error'
        });
      }
    }
  }

  @Action
  async [Actions.UPDATE_USER_IMAGE]({ data }) {
    const currentRole = store.getters.currentRole;
    const response = await axios.post(`/private/role/${currentRole.hash}/artist/upload-image`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    await sanctum.getUser();
    return response;
  }

  @Action
  async [Actions.LOAD_ROLE_SECTION_DATA]() {
    try {
      const currentRole = store.getters.currentRole;
      const sectionData = await axios.get('/private/role/' + currentRole.hash + '/profile/role-section-data');

      store.commit(Mutations.SET_SECTION_DATA, sectionData.data.data);
    } catch (error: any) {
      console.log(error);
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Error al cargar los datos de la sección'
        });
      }
    }
  }

  @Action
  async [Actions.LOAD_TOTAL_SOLD_DATA]() {
    try {
      const currentRole = store.getters.currentRole;
      const totalSoldData = await axios.get(
        '/private/role/' + currentRole.hash + '/profile/dashboard/statistics/total-sold'
      );

      store.commit(Mutations.SET_TOTAL_SOLD_DATA, totalSoldData.data.data);
    } catch (error: any) {
      console.log(error);
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Error al cargar los datos de la sección'
        });
      }
    }
  }

  @Action
  async [Actions.DELETE_SELF]() {
    try {
      await axios.post('/private/profile/delete');
      this.context.commit(Mutations.SET_ERROR, {});
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Public profile error'
        });
      }
    }
  }

  @Action
  async [Actions.VALIDATE_SELF]() {
    try {
      await axios.post('/private/profile/validate');
      this.context.commit(Mutations.SET_ERROR, {});
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Public profile error'
        });
      }
    }
  }

  @Action
  async [Actions.CANCEL_SUBSCRIPTION]({subscriptionHash}) {
    try {
      const currentRole = store.getters.currentRole;
      await axios.delete(`/private/role/${currentRole.hash}/profile/cancel-subscription/${subscriptionHash}`);
      this.context.commit(Mutations.SET_ERROR, {});

      return true;
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Public profile error'
        });
      }
    }
  }

  @Action
  async [Actions.REACTIVATE_DEVICE_SUBSCRIPTION]({deviceHash}) {
    try {
      const currentRole = store.getters.currentRole;
      await axios.post(`/private/role/${currentRole.hash}/profile/reactivate-device-subscription/${deviceHash}`);
      this.context.commit(Mutations.SET_ERROR, {});

      return true;
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Public profile error'
        });
      }
    }
  }
}
