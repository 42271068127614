enum Web3Actions {
	// action types
	INIT_METAMASK = "initMetamask",
	LOAD_WALLET = "loadWallet",
	SAVE_CURRENT_WALLET = "saveCurrentWallet",
}

enum Web3Mutations {
	// mutation types
	SET_ERROR = "setError",
	SET_WALLET = "setWallet",
	SET_ADDRESS = "setAddress",
	SET_ADDRESS_MIN = "setAddressMin",
	SET_ADDRESS_MICRO = "setAddressMicro",
	SET_HAS_METAMASK = "setHasMetamask",
}

enum Web3Errors {
	// mutation types
	METAMASK_NOT_INSTALLED = "METAMASK_NOT_INSTALLED",
	NO_BLOCKCHAIN_PROVIDER = "NO_BLOCKCHAIN_PROVIDER",
	WALLET_NOT_SELECTED = "WALLET_NOT_SELECTED",
}

export { Web3Actions, Web3Mutations, Web3Errors };
