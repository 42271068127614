import { App } from 'vue';
import VueClipboard from 'vue3-clipboard';

/**
 *
 * @param app
 */
export function initClipboard(app: App<Element>) {
  const Vue = app;
  // Vue.use(VueClipboard);
  Vue.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true
  });
}
