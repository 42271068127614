import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
// custom sanctum
import sanctum from '../../core/plugins/sanctum';

import { Artwork, ArtworkInfo, Discipline, SectionData } from '@/types/interfaces/global/ArtworkInterface';
import { ArtworkMutations, ArtworksActions } from '@/store/enums/ArtworkEnums';
import store from '@/store';
import axios from '@/core/plugins/axios';
import { Mutations } from '@/store/enums/StoreEnums';

@Module
export default class ArtworkModule extends VuexModule implements ArtworkInfo {
  artworksErrors = {};
  artwork = {} as Artwork | undefined;
  artworks = {} as [Artwork | undefined];
  artworksTotal = {} as number;

  artworksInAuction = {} as [Artwork | undefined];
  artworksForSale = {} as [Artwork | undefined];
  
  disciplines = {} as [Discipline | undefined];
  sectionData = {} as SectionData | undefined;

  /**
   * Artwork Section data for actual Role
   */
  get getSectionData(): SectionData | undefined {
    return this.sectionData;
  }

  /**
   * Get current user object
   * @returns [Artwork | undefined]
   */
  get getArtworks(): [Artwork | undefined] {
    return this.artworks;
  }

  get countArtworks(): number | undefined {
    return this.sectionData?.total_artworks;
  }

  get countArtworksToSell(): number | undefined {
    return this.sectionData?.total_artworks_on_sale;
  }

  get countArtworksToAuction(): number | undefined {
    return this.sectionData?.total_artworks_on_auction;
  }

  /**
   * Get current user object
   * @returns Artwork | undefined
   */
  get getArtwork(): Artwork | undefined {
    return this.artwork;
  }

  /**
   * Get total artworks
   * @returns number
   */
  get getTotalArtworks(): number {
    return this.artworksTotal;
  }

  /**
   * Get current user object
   * @returns [Artwork | undefined]
   */
  get getArtworksInAuction(): [Artwork | undefined] {
    return this.artworksInAuction;
  }

  /**
   * Get current user object
   * @returns [Artwork | undefined]
   */
  get getArtworksForSale(): [Artwork | undefined] {
    return this.artworksForSale;
  }

  /**
   * Get authentification artworksErrors
   * @returns array
   */
  get getArtworksartworksErrors() {
    return this.artworksErrors;
  }

  /**
   * Get artworks disciplines
   * @returns array
   */
  get getDisciplines() {
    return this.disciplines;
  }

  @Mutation
  [ArtworkMutations.SET_SECTION_DATA](sectionData) {
    this.sectionData = sectionData;
  }

  @Mutation
  [ArtworkMutations.SET_ERROR](error) {
    this.artworksErrors = { ...error };
  }

  @Mutation
  [ArtworkMutations.SET_ARTWORKS](artworks) {
    this.artworks = artworks;
  }

  @Mutation
  [ArtworkMutations.SET_ARTWORK](artwork) {
    this.artwork = artwork;
  }

  @Mutation
  [ArtworkMutations.ADD_ARTWORK](artwork) {
    this.artworks.push(artwork);
  }

  @Mutation
  [ArtworkMutations.SET_ARTWORK_FOR_SALE](artworkSell) {
    if (this.artwork) {
      this.artwork.active_artwork_sell = artworkSell;
    }
  }

  @Mutation
  [ArtworkMutations.UPDATE_ARTWORK_FOR_SALE](artworkHash, artworkSell) {
    const artworkFound = this.artworks.find((artwork) => {
      if (artwork !== undefined) {
        return artwork.hash === artworkHash;
      }
    });
    if (artworkFound !== undefined) {
      artworkFound.active_artwork_sell = artworkSell;
    }
  }

  @Mutation
  [ArtworkMutations.SET_ARTWORK_FOR_AUCTION](auction) {
    if (this.artwork) {
      this.artwork.active_auction = auction;
    }
  }

  @Mutation
  [ArtworkMutations.UPDATE_ARTWORK_FOR_AUCTION](artworkHash, auction) {
    const artworkFound = this.artworks.find((artwork) => {
      if (artwork !== undefined) {
        return artwork.hash === artworkHash;
      }
    });
    if (artworkFound !== undefined) {
      artworkFound.active_auction = auction;
    }
  }

  @Mutation
  [ArtworkMutations.SET_ARTWORKS_TOTAL](total) {
    this.artworksTotal = total;
  }

  @Mutation
  [ArtworkMutations.SET_DISCIPLINES](disciplines) {
    this.disciplines = disciplines;
  }

  @Action
  async [ArtworksActions.LOAD_ARTWORK_SECTION_DATA]() {
    try {
      const sectionData = await sanctum.loadArtworkSectionData();

      store.commit(ArtworkMutations.SET_SECTION_DATA, sectionData.data);
    } catch (error: any) {
      console.log(error);
      if (error.response.data) {
        this.context.commit(ArtworkMutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(ArtworkMutations.SET_ERROR, {
          message: 'Error al cargar los datos de la sección'
        });
      }
    }
  }

  @Action
  async [ArtworksActions.LOAD_ARTWORKS]({ per_page, page, filters }) {
    try {
      await sanctum.getRoleArtworks(per_page, page, filters);
    } catch (error: any) {
      console.log(error);
      if (error.response.data) {
        this.context.commit(ArtworkMutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(ArtworkMutations.SET_ERROR, {
          message: 'Error al cargar las obras'
        });
      }
    }
  }

  @Action
  async [ArtworksActions.LOAD_ARTWORK](artworkHash) {
    try {
      await sanctum.getRoleArtwork(artworkHash);
    } catch (error: any) {
      console.log(error);
      if (error.response.data) {
        this.context.commit(ArtworkMutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(ArtworkMutations.SET_ERROR, {
          message: 'Error al cargar las obras'
        });
      }
    }
  }

  @Action
  async [ArtworksActions.LOAD_DISCIPLINES]() {
    const currentRole = store.getters.currentRole;
    try {
      const response = await axios.get(`/private/role/${currentRole.hash}/artwork/disciplines/list`);
      this.context.commit(ArtworkMutations.SET_DISCIPLINES, response.data.data);
    } catch (e) {
      console.error('ERROR', e);
    }
  }

  @Action
  async [ArtworksActions.SAVE_ARTWORK]({ data, artworkHash }) {
    const currentRole = store.getters.currentRole;

    try {
      const response = await axios.post(
        `/private/role/${currentRole.hash}/artwork/save${artworkHash ? '/' + artworkHash : ''}`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (response.data.data !== undefined) {
        if (response.data.data.hash) {
          this.context.commit(ArtworkMutations.ADD_ARTWORK, response.data.data);
        }
      }

      return response;
    } catch (error: any) {
      return false;
    }
  }

  @Action
  async [ArtworksActions.EDIT_ARTWORK]({ artworkData, artworkHash }) {
    try {
      const artwork = await sanctum.editArtwork(artworkHash, artworkData);

      if (artwork !== undefined) {
        if (artwork.hash) {
          this.context.commit(ArtworkMutations.SET_ARTWORK, artwork);
        }
      }

      return artwork;
    } catch (error: any) {
      return false;
    }
  }

  @Action
  async [ArtworksActions.TOGGLE_ARTWORK_VISIBILITY](artworkHash) {
    try {
      const artwork = await sanctum.toggleArtworkVisibility(artworkHash);

      if (artwork !== undefined) {
        if (artwork.hash) {
          this.context.commit(ArtworkMutations.SET_ARTWORK, artwork);
        }
      }

      return artwork;
    } catch (error: any) {
      return false;
    }
  }

  @Action
  async [ArtworksActions.SAVE_ARTWORK_TO_SALE]({ artworkHash, artworkData }) {
    try {
      const response = await sanctum.saveArtworkToSell(artworkHash, artworkData);

      if (response !== false) {
        this.context.commit(ArtworkMutations.SET_ARTWORK_FOR_SALE, response.data);
        this.context.commit(ArtworkMutations.UPDATE_ARTWORK_FOR_SALE, {
          artworkHash: artworkHash,
          artworkSell: response.data
        });
      }

      return response;
    } catch (error: any) {
      return false;
    }
  }

  @Action
  async [ArtworksActions.SAVE_ARTWORK_TO_AUCTION]({ artworkHash, artworkData }) {
    try {
      const response = await sanctum.saveArtworkToAuction(artworkHash, artworkData);

      if (response !== false) {
        this.context.commit(ArtworkMutations.SET_ARTWORK_FOR_AUCTION, response.data);
        this.context.commit(ArtworkMutations.UPDATE_ARTWORK_FOR_AUCTION, {
          artworkHash: artworkHash,
          artworkSell: response.data
        });
      }

      return response;
    } catch (error: any) {
      return false;
    }
  }

  @Action
  async [ArtworksActions.CANCEL_AUCTION](auctionHash) {
    const currentRole = store.getters.currentRole;
    try {
      await axios.post(`/private/role/${currentRole.hash}/auction/cancel/${auctionHash}`);

      this.context.commit(Mutations.SET_ERROR, {});
      if (this.artwork) {
        this.artwork.active_auction = undefined;
      }
    } catch (error: any) {
      console.error('ERROR', error);
      this.context.commit(Mutations.SET_ERROR, {
        message: error.response.data.message
      });
      throw new Error(ArtworksActions.CANCEL_AUCTION);
    }
  }

  @Action
  async [ArtworksActions.CANCEL_SELL](artworkSellHash) {
    const currentRole = store.getters.currentRole;
    try {
      const response = await axios.post(`/private/role/${currentRole.hash}/artwork-sell/cancel/${artworkSellHash}`);
      this.context.commit(Mutations.SET_ERROR, {});
      if (this.artwork) {
        this.artwork.active_artwork_sell = undefined;
      }
    } catch (error: any) {
      console.error('ERROR', error);
      this.context.commit(Mutations.SET_ERROR, {
        message: error.response.data.message
      });
      throw new Error(ArtworksActions.CANCEL_SELL);
    }
  }

  @Action
  async [ArtworksActions.CREATE_NFT]({ artworkHash, artworkData }) {
    const currentRole = store.getters.currentRole;
    try {
      const response = await axios.post(
        `/private/role/${currentRole.hash}/artwork/tokenize/${artworkHash}`,
        artworkData
      );
      if (this.artwork) {
        this.artwork.latestBlockchainTrack = response.data;
      }
    } catch (error: any) {
      console.error('ERROR', error);
      this.context.commit(Mutations.SET_ERROR, {
        message: error.response.data.message
      });
      throw new Error(ArtworksActions.CREATE_NFT);
    }
  }
}
