import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import sanctum from '../../core/plugins/sanctum';

import {
  UserAuthInfo,
  User,
  Role,
  PaymentMethod,
  Configurations,
  Subscription,
  BillingAddress,
  ShippingAddress
} from '@/types/interfaces/global/UserInterface';
import store from '@/store';
import axios from '@/core/plugins/axios';

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  role = {} as Role;
  isAuthenticated = false;
  isPending = false;

  get currentUser(): User {
    return this.user;
  }

  get currentConfiguration(): Configurations {
    return this.currentRole.configurations;
  }

  get currentRole(): Role {
    return this.role;
  }

  get currentSusbcription(): Subscription {
    return this.currentRole.plan_subscription;
  }

  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get isUserPending(): boolean {
    return this.currentRole.status === 'pending';
  }

  get getErrors() {
    return this.errors;
  }

  get roleWallets(): PaymentMethod[] | [] {
    return this.role.payment_methods_crypto;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
  }

  @Mutation
  [Mutations.SET_CURRENT_ROLE](role) {
    this.role = role;
  }

  @Mutation
  [Mutations.SET_WALLETS](wallets) {
    this.role.payment_methods_crypto = wallets;
  }

  @Mutation
  [Mutations.ADD_PAYMENT_METHOD](paymentMethod: PaymentMethod) {
    this.role.payment_methods_fiat.push(paymentMethod);
  }

  @Mutation
  [Mutations.ADD_BILLING_ADDRESS](billingAddress: BillingAddress) {
    this.role.billing_addresses.push(billingAddress);
  }

  @Mutation
  [Mutations.EDIT_BILLING_ADDRESS](billingAddress: BillingAddress) {
    this.role.billing_addresses = this.role.billing_addresses.map((x) =>
      x.hash === billingAddress.hash ? billingAddress : x
    );
  }

  @Mutation
  [Mutations.ADD_SHIPPING_ADDRESS](shippingAddress: ShippingAddress) {
    this.role.shipping_addresses.push(shippingAddress);
  }

  @Action
  async [Actions.LOGIN](credentials) {
    try {
      this.context.commit(Mutations.PURGE_AUTH);

      await sanctum.login(credentials);
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Login error'
        });
      }
    }
  }

  @Action
  async [Actions.LOGOUT]() {
    try {
      const result = await sanctum.logout();
      this.context.commit(Mutations.PURGE_AUTH);
      return result;
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Logout error'
        });
      }
      return false;
    }
  }

  @Action
  async [Actions.REGISTER](credentials) {
    try {
      await sanctum.registerSimple(credentials);
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Login error'
        });
      }
    }
  }

  @Action
  async [Actions.FORGOT_PASSWORD](credentials) {
    try {
      await sanctum.forgotPassword(credentials);
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Login error'
        });
      }
    }
  }

  @Action
  async [Actions.RESET_PASSWORD](credentials) {
    try {
      await sanctum.resetPassword(credentials);
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: 'Login error'
        });
      }
    }
  }

  @Action
  async [Actions.VERIFY_AUTH](payload) {
    return sanctum.getUser();
  }

  @Action
  async [Actions.REFRESH_WALLETS]() {
    try {
      const wallets = await sanctum.fetchWallets();
      this.context.commit(Mutations.SET_WALLETS, wallets);
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: Actions.REFRESH_WALLETS
        });
      }
    }
  }

  @Action
  async [Actions.CREATE_SHIPPING_ADDRESS](shippingData) {
    try {
      const shippingAddress = await sanctum.addShippingAddress(shippingData);
      await sanctum.getUser();

      return shippingAddress;
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: Actions.REFRESH_WALLETS
        });
      }
    }
  }

  @Action
  async [Actions.UPDATE_SHIPPING_ADDRESS]({ shippingAddressHash, shippingData }) {
    try {
      const shippingAddress = await sanctum.editShippingAddress(shippingAddressHash, shippingData);
      await sanctum.getUser();

      return shippingAddress;
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: Actions.REFRESH_WALLETS
        });
      }
    }
  }

  @Action
  async [Actions.DISABLE_SHIPPING_ADDRESS](shippingAddressHash) {
    try {
      await sanctum.disableShippingAddress(shippingAddressHash);
      await sanctum.getUser();
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: Actions.REFRESH_WALLETS
        });
      }
    }
  }

  @Action
  async [Actions.CREATE_BILLING_ADDRESS](billingData) {
    try {
      const billingAddress = await sanctum.addBillingAddress(billingData);
      await sanctum.getUser();

      return billingAddress;
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: Actions.REFRESH_WALLETS
        });
      }
    }
  }

  @Action
  async [Actions.UPDATE_BILLING_ADDRESS]({ billingAddressHash, billingData }) {
    try {
      const billingAddress = await sanctum.editBillingAddress(billingAddressHash, billingData);
      await sanctum.getUser();

      return billingAddress;
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: Actions.REFRESH_WALLETS
        });
      }
    }
  }

  @Action
  async [Actions.DISABLE_BILLING_ADDRESS](billingAddressHash) {
    try {
      await sanctum.disableBillingAddress(billingAddressHash);
      await sanctum.getUser();
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: Actions.REFRESH_WALLETS
        });
      }
      return false;
    }
  }

  @Action
  async [Actions.CREATE_PAYMENT_METHOD](paymentMethodData) {
    try {
      const paymentMethod = await sanctum.addPaymentMethod(paymentMethodData);
      await sanctum.getUser();
      return paymentMethod;
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: Actions.REFRESH_WALLETS
        });
      }
      return false;
    }
  }

  @Action
  async [Actions.EDIT_PAYMENT_METHOD]({ hash, data }) {
    try {
      const paymentMethod = await sanctum.updatePaymentMethod(hash, data);
      await sanctum.getUser();
      return paymentMethod;
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: Actions.REFRESH_WALLETS
        });
      }

      return false;
    }
  }

  @Action
  async [Actions.DISABLE_PAYMENT_METHOD](paymentMethodHash) {
    try {
      await sanctum.disablePaymentMethod(paymentMethodHash);
      await sanctum.getUser();
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(Mutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(Mutations.SET_ERROR, {
          message: Actions.REFRESH_WALLETS
        });
      }
    }
  }

  @Action
  async [Actions.SEND_CONTACT_MESSAGE]({ subject, message }) {
    const currentRole = store.getters.currentRole;
    return await axios.post(`/private/role/${currentRole.hash}/contact`, { subject, message });
  }
}
