import { App } from 'vue';
import VueGoogleMaps from 'vue-google-maps-community-fork';
/**
 * Initialize VueApexChart component
 * @param app vue instance
 */
export function initGoogleMaps(app: App<Element>) {
  app.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
      libraries: 'places,drawing,geometry'
    }
  });
}
