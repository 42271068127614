import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import axios from "@/core/plugins/axios";
import store from "@/store";


interface StoreInfo {
    artists: Array<object> | undefined;
}

@Module
export default class ArtistModule extends VuexModule implements StoreInfo {
    artists = [];
    categoriesList = Array;

    /**
     * Get artists
     * @returns [Array | undefined]
     */
    get getArtists() {
        return this.artists;
    }

    /**
     * Get categories list
     * @returns [Array | undefined]
     */
    get getCategoriesList() {
        return this.categoriesList;
    }

    @Mutation
    [Mutations.SET_ARTISTS](artists) {
        this.artists = artists;
    }

    @Mutation
    [Mutations.SET_CATEGORIES_LIST](list) {
        this.categoriesList = list;
    }

    @Action
    async [Actions.SET_ARTISTS](author) {
        try {
            const currentRole = store.getters.currentRole;
            const myArray = author.split(" ");
            const name = myArray[0]
            const surname = myArray[1]
            const response = await axios.get(
                `/private/role/${currentRole.hash}/artist/searchByNameAndSurname`, { params: {name, surname}}
            );
            this.context.commit(Mutations.SET_ARTISTS, response.data.data);
        } catch (e: any) {
            this.context.commit(
                Mutations.SET_ARTISTS,
                e.response.data.errors
            );
        }
    }

    @Action
    async [Actions.ADD_AUTHOR]({ name, surname }) {
        const currentRole = store.getters.currentRole;
        return await axios.post(
            `/private/role/${currentRole.hash}/artist/createByNameAndSurname`, { name, surname }
        );
    }

    @Action
    async [Actions.CREATE_CATEGORY]({ icon, name }) {
        const currentRole = store.getters.currentRole;
        const i18n = [{ es: { name }}]
        return await axios.post(
            `/private/role/${currentRole.hash}/artist/attachment/category/create`, { type: icon, i18n }
        );
    }


    @Action
    async [Actions.GET_CATEGORIES]() {
        const currentRole = store.getters.currentRole;
        const response =  await axios.get(
            `/private/role/${currentRole.hash}/artist/attachment/category/list`
        ).then((res) => {
            this.context.commit(Mutations.SET_CATEGORIES_LIST, res.data.data);
        });
        return response;
    }

    @Action
    async [Actions.GET_FILES_BY_CATEGORY]({ hash }) {
        const currentRole = store.getters.currentRole;
        return await axios.get(
            `/private/role/${currentRole.hash}/artist/attachment/category/${hash}/list`
        );
    }

    @Action
    async [Actions.UPLOAD_CATEGORY_FILE]({ data, hash }) {
        const currentRole = store.getters.currentRole;
        return await axios.post(
            `/private/role/${currentRole.hash}/artist/attachment/category/${hash}/upload`,
            data,
            { headers : {
                    'Content-Type': 'multipart/form-data'
                }}
        );
    }

    @Action
    async [Actions.DELETE_CATEGORY_FILE]({categoryHash, atachmentHash }) {
        const currentRole = store.getters.currentRole;
        return await axios.post(
            `/private/role/${currentRole.hash}/artist/attachment/category/${categoryHash}/delete/${atachmentHash}`,
        );
    }

    @Action
    async [Actions.DELETE_CATEGORY](categoryHash) {
        const currentRole = store.getters.currentRole;
        return await axios.delete(
            `/private/role/${currentRole.hash}/artist/attachment/category/${categoryHash}/delete`,
        );
    }

}
