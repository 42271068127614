import { Mutation } from 'vuex-module-decorators';

enum Actions {
  // action types
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  UPDATE_USER = 'updateUser',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  LOAD_CART = 'loadCart',
  ADD_CART_ITEM = 'addCartItemAction',
  UPDATE_CART_ITEM = 'updateCartItemAction',
  REMOVE_CART_ITEM = 'removeCartItemAction',
  UPDATE_PROFILE = 'updateProfile',
  UPDATE_PUBLIC_PROFILE = 'updatePublicProfile',
  CHANGE_ROLE_PUBLIC_PROFILE_VISIBILITY = 'CHANGE_ROLE_PUBLIC_PROFILE_VISIBILITY',

  REFRESH_WALLETS = 'refreshWallets',
  SET_ARTISTS = 'setArtistsAction',
  ADD_AUTHOR = 'addAuthor',
  GET_PRODUCTS = 'getProducts',
  GET_PRODUCTS_GROUP = 'getProductsGroup',
  UPDATE_USER_IMAGE = 'updateUserImage',
  LOAD_ROLE_SECTION_DATA = 'loadRoleSectionData',
  LOAD_TOTAL_SOLD_DATA = 'LOAD_TOTAL_SOLD_DATA',

  CREATE_SHIPPING_ADDRESS = 'createShippingAddress',
  UPDATE_SHIPPING_ADDRESS = 'updateShippingAddress',
  DISABLE_SHIPPING_ADDRESS = 'disableShippingAddress',

  CREATE_BILLING_ADDRESS = 'createBillingAddress',
  UPDATE_BILLING_ADDRESS = 'updateBillingAddress',
  DISABLE_BILLING_ADDRESS = 'disableBillingAddress',

  CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD',
  DISABLE_PAYMENT_METHOD = 'DISABLE_PAYMENT_METHOD',
  EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD',

  CREATE_CATEGORY = 'createCategory',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  GET_CATEGORIES = 'getCategories',
  GET_FILES_BY_CATEGORY = 'getFilesByCategory',
  UPLOAD_CATEGORY_FILE = 'uploadCategoryFile',
  DELETE_CATEGORY_FILE = 'deleteCategoryFile',

  LOAD_COUNTRIES = 'LOAD_COUNTRIES',
  DELETE_SELF = 'DELETE_SELF',
  VALIDATE_SELF = 'VALIDATE_SELF',

  SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE',

  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  REACTIVATE_DEVICE_SUBSCRIPTION = 'REACTIVATE_DEVICE_SUBSCRIPTION',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_ERROR = 'setError',
  SET_CART_ERROR = 'setCartError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  SET_CART = 'setCart',
  ADD_CART_ITEM = 'addCartItem',
  REMOVE_CART_ITEM = 'removeCartItem',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_CURRENT_ROLE = 'setCurrentRole',
  SET_ARTISTS = 'setArtists',
  SET_PRODUCTS = 'setProducts',
  SET_PRODUCTS_GROUP = 'setProductsGroup',
  SET_CATEGORIES_LIST = 'setCategoriesList',

  ADD_SHIPPING_ADDRESS = 'addShippingAddress',
  SET_SHIPPING_DATA_REQUEST = 'setShippingDataRequest',

  ADD_BILLING_ADDRESS = 'addBillingAddress',
  EDIT_BILLING_ADDRESS = 'editBillingAddress',
  SET_BILLING_DATA_REQUEST = 'SET_BILLING_DATA_REQUEST',

  ADD_PAYMENT_METHOD = 'addPaymentMethod',
  SET_PAYMENT_METHOD_DATA_REQUEST = 'SET_PAYMENT_METHOD_DATA_REQUEST',
  UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD',

  SET_WALLETS = 'setWallets',

  SET_COUNTRIES = 'SET_COUNTRIES',
  SET_SECTION_DATA = 'SET_SECTION_DATA',
  SET_TOTAL_SOLD_DATA = 'SET_TOTAL_SOLD_DATA'
}

export { Actions, Mutations };
