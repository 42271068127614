import LayoutConfigTypes from '@/core/config/LayoutConfigTypes';

const config: LayoutConfigTypes = {
  themeName: 'Metronic',
  themeVersion: '8.0.38',
  demo: 'A4A',
  main: {
    type: 'default',
    primaryColor: '#B55837',
    logo: {
      dark: '/media/logos/logo-1-dark.svg',
      light: '/media/logos/logo-1.svg'
    }
  },
  illustrations: {
    set: 'dozzy-1'
  },
  loader: {
    logo: '/media/logos/logo-1-dark.svg',
    display: true,
    type: 'spinner-message'
  },
  scrollTop: {
    display: true
  },
  header: {
    display: true,
    menuIcon: 'font',
    width: 'fluid',
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  toolbar: {
    display: true,
    width: 'fluid',
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  aside: {
    display: true,
    theme: 'dark',
    fixed: true,
    menuIcon: 'font',
    minimized: false,
    minimize: true,
    hoverable: true
  },
  content: {
    width: 'fixed'
  },
  footer: {
    width: 'fluid'
  }
};

export default config;
