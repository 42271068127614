import { createI18n } from 'vue-i18n/index';

import en from '@/core/locales/en';
import es from '@/core/locales/es';

const messages = {
  en: en,
  es: es
};

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  },
  es: {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  }
};

const i18n = createI18n({
  legacy: false,
  locale: 'es',
  globalInjection: true,
  numberFormats,
  messages
});

export default i18n;
