<template>
  <inline-svg v-if="name" height="100%" :src="require(`../assets/svg/${name}.svg`)"></inline-svg>
</template>

<script>
import { defineComponent } from 'vue';
import InlineSvg from 'vue-inline-svg';

export default defineComponent({
  name: 'BaseIcon',
  components: { InlineSvg },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  setup() {
    return {};
  }
});
</script>
<style lang="scss" scoped></style>
