import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ICreateAccount } from '@/types/interfaces/register/CreateAccountInterface';
import { RegisterActions, RegisterMutations } from '@/store/enums/RegisterEnums';

export interface RegisterInfo {
  errors: unknown;
  registerData: ICreateAccount;
}

@Module
export default class RegisterModule extends VuexModule implements RegisterInfo {
  errors: unknown;
  registerData = {} as ICreateAccount;

  /**
   * Get current register data object
   * @returns ICreateAccount
   */
  get currentData(): ICreateAccount {
    return this.registerData;
  }

  @Action
  async [RegisterActions.INIT_REGISTER]() {
    try {
      this.context.commit(RegisterMutations.RESET_REGISTER);
    } catch (error: any) {
      if (error.response.data) {
        this.context.commit(RegisterMutations.SET_ERROR, {
          message: error.response.data.message
        });
      } else {
        this.context.commit(RegisterMutations.SET_ERROR, {
          message: 'Logout error'
        });
      }
    }
  }

  @Mutation
  [RegisterMutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [RegisterMutations.RESET_REGISTER]() {
    this.errors = [];
    this.registerData = {
      roleable: '',
      name: '',
      surname: '',
      phone: '',
      contact_email: '',

      shippingAddress_name: '',
      shippingAddress_address: '',
      shippingAddress_country: '',
      shippingAddress_phone: '',
      shippingAddress_region: '',
      shippingAddress_region_cp: '',
      shippingAddress_cp: '',
      shippingAddress_city: '',
      shipping_address: {
        name: '',
        additional_info: '',
        address: '',
        phone: '',
        country_id: '',
        region_id: '',
        city: '',
        postal_code: ''
      },

      billingAddress_business_name: '',
      billingAddress_name: '',
      billingAddress_surname: '',
      billingAddress_address: '',
      billingAddress_nif: '',
      billingAddress_phone: '',
      billingAddress_country: '',
      billingAddress_region: '',
      billingAddress_city: '',
      billingAddress_cp: '',
      billingAddress_region_cp: '',
      billingAddress_type: '',
      billing_address: {
        business_name: '',
        name: '',
        surname: '',
        address: '',
        nif: '',
        phone: '',
        country: '',
        region: '',
        city: '',
        postal_code: '',
        type: ''
      },

      payment_method: {
        type: '',
        payment_source_id: '',
        is_default: true
      },
      nameOnCard: '',
      paymentType: ''
    } as ICreateAccount;
  }
}
