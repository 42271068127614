import { createApp } from 'vue';
import App from './App.vue';

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import i18n from '@/core/plugins/i18n';

// axios
import axios from './core/plugins/axios';

// custom sanctum
import sanctum from './core/plugins/sanctum';

//imports for app initialization
import ApiService from '@/core/services/ApiService';
import { initApexCharts } from '@/core/plugins/apexcharts';
import { initInlineSvg } from '@/core/plugins/inline-svg';
import { initVeeValidate } from '@/core/plugins/vee-validate';
import { registerComponents } from '@/core/plugins/global-components';
import { initClipboard } from '@/core/plugins/v-clipboard';
import { initTooltip } from '@/core/plugins/tooltip';
import { initVSelect } from '@/core/plugins/v-select';
import { initGoogleMaps } from '@/core/plugins/google-maps';
import { initDatePicker } from '@/core/plugins/vue-datepicker';

import '@/core/plugins/prismjs';
import 'bootstrap';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$sanctum = sanctum;

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
registerComponents(app);
initClipboard(app);
initTooltip(app);
initVSelect(app);
initGoogleMaps(app);
initDatePicker(app);
initVeeValidate();

app.use(i18n);

app.mount('#app');
