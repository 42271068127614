import vSelect from 'vue-select';
import { App } from 'vue';

import 'vue-select/dist/vue-select.css';

export function initVSelect(app: App<Element>) {
  const Vue = app;
  // Vue.use(VueClipboard);
  Vue.component('v-select', vSelect);
}
