import { Tooltip } from 'bootstrap';
import { App } from 'vue';

const tooltipBootstrap = {
  mounted(el) {
    const tooltip = new Tooltip(el);
  }
};

export function initTooltip(app: App<Element>) {
  const Vue = app;
  // Vue.use(VueClipboard);
  Vue.directive('tooltip', tooltipBootstrap);
}
