import axios from "axios";

const instance = createInstance(process.env.VUE_APP_API_URL + '/api/v1');

function createInstance(baseURL) {
	const params = {
		baseURL,
		withCredentials: true,
	};
	return axios.create(params);
}

export default instance;
