import { Mutations } from '@/store/enums/StoreEnums';
import axios from './axios';
import store from '@/store';
import { ArtworkMutations } from '@/store/enums/ArtworkEnums';
import { Cart, CartItem, Checkout, ProductGroup } from '@/types/interfaces/global/MarketInterface';
import { BillingAddress, PaymentMethod, ShippingAddress } from '@/types/interfaces/global/UserInterface';
import { Artwork } from '@/types/interfaces/global/ArtworkInterface';
import { Artist } from '@/types/interfaces/global/ArtistInterface';
import { Location, RoleableDeviceConfig, Temperature } from '@/types/interfaces/global/Iot';

async function getUser() {
  const user = await fetchUser();
  if (user) {
    store.commit(Mutations.SET_AUTH, user);
    // Si está undefined, el usuario podrá cambiar de role aunque este no sea el propietario.
    if (user.roles?.length > 0) {
      const role = user.roles.find((role) => role?.is_owner === true);
      store.commit(Mutations.SET_CURRENT_ROLE, role);
    }
  } else {
    return false;
  }
}

async function login(credentials) {
  await axios.request({
    url: '/sanctum/csrf-cookie',
    baseURL: process.env.VUE_APP_API_URL
  });
  await axios.post('/auth/login', credentials);
}

async function registerSimple(credentials) {
  await axios.request({
    url: '/sanctum/csrf-cookie',
    baseURL: process.env.VUE_APP_API_URL
  });
  await axios.post('/public/register/initialRegister', credentials);
}

async function fetchUser() {
  try {
    const response = await axios.get('/private/profile/me');
    return response.data.data;
  } catch (e) {
    return false;
  }
}

async function forgotPassword(credentials) {
  await axios.post('/auth/forgot-password', credentials);
}

async function resetPassword(credentials) {
  await axios.request({
    url: '/sanctum/csrf-cookie',
    baseURL: process.env.VUE_APP_API_URL
  });
  await axios.post('/auth/reset-password', credentials);
}

async function logout() {
  try {
    await axios.get('/auth/logout');
    store.commit(Mutations.PURGE_AUTH);
    return true;
  } catch (e) {
    return false;
  }
}

async function verifyEmail() {
  await axios.post('/auth/email-verify-resend');
}

async function getRoleArtworks(perPage, page, filters) {
  const artworks = await fetchRoleArtworks(perPage, page, filters);
  if (artworks) {
    store.commit(ArtworkMutations.SET_ARTWORKS, artworks.data);
    store.commit(ArtworkMutations.SET_ARTWORKS_TOTAL, artworks.total);
  } else {
    return false;
  }
}

async function loadArtworkSectionData() {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.get('/private/role/' + currentRole.hash + '/artwork/section-data');

    return response.data;
  } catch (e) {
    return false;
  }
}

async function fetchRoleArtworks(perPage = 9, page = 1, filters = null) {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.get('/private/role/' + currentRole.hash + '/artwork/list/' + perPage + '/' + page, {
      params: { filters }
    });
    return response.data.data;
  } catch (e) {
    return false;
  }
}

async function getRoleArtwork(artworkHash) {
  const artwork = await fetchRoleArtwork(artworkHash);
  if (artwork) {
    store.commit(ArtworkMutations.SET_ARTWORK, artwork.data);
  } else {
    return false;
  }
}

async function fetchRoleArtwork(artworkHash) {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.get('/private/role/' + currentRole.hash + '/artwork/view/' + artworkHash);
    return response.data;
  } catch (e) {
    return false;
  }
}

async function saveArtworkToSell(artworkHash, artworkData) {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.post(
      '/private/role/' + currentRole.hash + '/artwork-sell/save/' + artworkHash,
      artworkData
    );
    return response.data;
  } catch (e) {
    return false;
  }
}

async function saveArtworkToAuction(artworkHash, artworkData) {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.post(
      '/private/role/' + currentRole.hash + '/auction/save/' + artworkHash,
      artworkData
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function editArtwork(artworkHash, artworkData): Promise<Artwork> {
  const currentRole = store.getters.currentRole;
  const response = await axios.put('/private/role/' + currentRole.hash + '/artwork/' + artworkHash, artworkData);
  return response.data.data;
}

async function toggleArtworkVisibility(artworkHash): Promise<Artwork> {
  const currentRole = store.getters.currentRole;
  const response = await axios.post('/private/role/' + currentRole.hash + '/artwork/toggleVisibility/' + artworkHash);
  return response.data.data;
}

async function saveWallet(wallet) {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.post(
      '/private/role/' + currentRole.hash + '/payment-method/createWalletPaymentMethod',
      { wallet_address: wallet }
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function fetchWallets() {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.get('/private/role/' + currentRole.hash + '/payment-method/listCryptoPaymentMethods');
    return response.data.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function addPaymentMethod(paymentMethodData): Promise<PaymentMethod> {
  const currentRole = store.getters.currentRole;
  const response = await axios.post('/private/role/' + currentRole.hash + '/payment-method', paymentMethodData);
  return response.data.data;
}

async function addPaymentMethodPublic(paymentMethodData): Promise<PaymentMethod> {
  const response = await axios.post('/public/profile/payment-method/create', paymentMethodData);
  return response.data.data;
}

async function getPaymentMethods(): Promise<PaymentMethod> {
  const currentRole = store.getters.currentRole;
  const response = await axios.get('/private/role/' + currentRole.hash + '/payment-method/');
  return response.data.data;
}

async function updatePaymentMethod(hash, data): Promise<PaymentMethod> {
  const currentRole = store.getters.currentRole;
  const response = await axios.put(`/private/role/${currentRole.hash}/payment-method/${hash}`, data);
  return response.data.data;
}

async function disablePaymentMethod(paymentMethodHash) {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.post(
      '/private/role/' + currentRole.hash + '/payment-method/disable/' + paymentMethodHash
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function addShippingAddress(shippingAddresData): Promise<ShippingAddress> {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(
    '/private/role/' + currentRole.hash + '/shipping-address/create',
    shippingAddresData
  );
  return response.data.data;
}

async function editShippingAddress(shippingAddressHash, shippingAddresData): Promise<ShippingAddress> {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(
    '/private/role/' + currentRole.hash + '/shipping-address/edit/' + shippingAddressHash,
    shippingAddresData
  );

  return response.data.data;
}

async function disableShippingAddress(shippingAddressHash) {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.post(
      '/private/role/' + currentRole.hash + '/shipping-address/disable/' + shippingAddressHash
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function addBillingAddress(billingAddressData): Promise<BillingAddress> {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(
    '/private/role/' + currentRole.hash + '/billing-address/create',
    billingAddressData
  );
  return response.data.data;
}

async function editBillingAddress(billingAddressHash, billingAddresData): Promise<BillingAddress> {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(
    '/private/role/' + currentRole.hash + '/billing-address/edit/' + billingAddressHash,
    billingAddresData
  );
  return response.data.data;
}

async function disableBillingAddress(billingAddressHash) {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.post(
      '/private/role/' + currentRole.hash + '/billing-address/disable/' + billingAddressHash
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function listProductsByType(type) {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(`/private/role/${currentRole.hash}/products/list-by-type?type=${type}`);
  return response.data;
}

async function getCart(currentRole): Promise<Cart> {
  const response = await axios.get(`/private/role/${currentRole.hash}/cart`);
  return response.data.data;
}

async function addCartItem(productGroup: ProductGroup, quantity: number): Promise<Cart> {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(`/private/role/${currentRole.hash}/cart/add/${productGroup.hash}`, {
    quantity: quantity
  });
  return response.data.data;
}

async function updateCartItem(cartItem: CartItem): Promise<Cart> {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(`/private/role/${currentRole.hash}/cart/update/${cartItem.id}`, {
    quantity: cartItem.quantity
  });
  return response.data.data;
}

async function removeCartItem(cartItem: CartItem): Promise<Cart> {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(`/private/role/${currentRole.hash}/cart/remove/${cartItem.id}`);
  return response.data.data;
}

async function createOrder() {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(`/private/role/${currentRole.hash}/order`);
  return response.data.data;
}

async function modifyOrder(orderHash, checkout: Checkout) {
  const currentRole = store.getters.currentRole;
  const response = await axios.put(`/private/role/${currentRole.hash}/order/${orderHash}`, checkout);
  return response.data.data;
}

async function checkout(hash: string) {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(`/private/role/${currentRole.hash}/order/${hash}/checkout`);
  return response.data.data;
}

async function getOrders() {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(`/private/role/${currentRole.hash}/order`, {});
  return response.data.data;
}

async function getOrder(orderHash) {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(`/private/role/${currentRole.hash}/order/${orderHash}`, {});
  return response.data.data;
}

async function getArtists(): Promise<Artist[]> {
  const response = await axios.get(`/public/artist/list-public-validated`);
  return response.data.data;
}

async function loadRoleSectionData() {
  try {
    const currentRole = store.getters.currentRole;
    const response = await axios.get('/private/role/' + currentRole.hash + '/profile/section-data');

    return response.data;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function attachRolleableProduct(roleableProductHash: string, artworkHash: string) {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(
    `/private/role/${currentRole.hash}/artwork/link-device/${roleableProductHash}/to-artwork/${artworkHash}`
  );
  return response.data.data;
}

async function detachRolleableProduct(roleableProductHash: string, artworkHash: string) {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(
    `/private/role/${currentRole.hash}/artwork/unlink-device/${roleableProductHash}/from-artwork/${artworkHash}`
  );
  return response.data.data;
}

async function getRolleableProductTemperatures(
  roleableProductHash: string,
  start: moment.Moment,
  end: moment.Moment
): Promise<Temperature[]> {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(
    `/private/role/${currentRole.hash}/roleable-product/${roleableProductHash}/info/temperature`,
    {
      params: {
        from: start.toISOString(),
        to: end.toISOString()
      }
    }
  );
  return response.data.data;
}

async function getRolleableProductLocations(
  roleableProductHash: string,
  start: moment.Moment,
  end: moment.Moment
): Promise<Location[]> {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(
    `/private/role/${currentRole.hash}/roleable-product/${roleableProductHash}/info/location`,
    {
      params: {
        from: start.toISOString(),
        to: end.toISOString()
      }
    }
  );
  return response.data.data;
}

async function getRolleableProductShocks(roleableProductHash: string, start: moment.Moment, end: moment.Moment) {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(
    `/private/role/${currentRole.hash}/roleable-product/${roleableProductHash}/info/shock`,
    {
      params: {
        from: start.toISOString(),
        to: end.toISOString()
      }
    }
  );
  return response.data.data;
}

async function getRolleableProductConfig(roleableProductHash: string) {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(`/private/role/${currentRole.hash}/roleable-product/${roleableProductHash}/config`);
  return response.data.data;
}

async function updateRolleableProductConfig(roleableProductHash: string, data: RoleableDeviceConfig) {
  const currentRole = store.getters.currentRole;
  const response = await axios.put(
    `/private/role/${currentRole.hash}/roleable-product/${roleableProductHash}/config`,
    data
  );
  return response.data.data;
}

async function getAuctionsOnSale(perPage: number, page: number) {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(`/private/role/${currentRole.hash}/auction/list-on-sale/${perPage}/${page}`);
  return response.data.data;
}

async function getAuctionsSold(perPage: number, page: number) {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(`/private/role/${currentRole.hash}/auction/list-sold/${perPage}/${page}`);
  return response.data.data;
}

async function getAuctionsSectionData() {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(`/private/role/${currentRole.hash}/auction/section-data`);
  return response.data.data;
}

async function getAuction(auctionHash: string | string[]) {
  const currentRole = store.getters.currentRole;
  const response = await axios.get(`/private/role/${currentRole.hash}/auction/view/${auctionHash}`);
  return response.data.data;
}

async function cancelAuction(auctionHash: string) {
  const currentRole = store.getters.currentRole;
  const response = await axios.post(`/private/role/${currentRole.hash}/auction/cancel/${auctionHash}`);

  return response.data.data;
}

export default {
  getUser,
  logout,
  verifyEmail,
  login,
  forgotPassword,
  resetPassword,
  registerSimple,
  fetchRoleArtworks,
  getRoleArtworks,
  getRoleArtwork,
  fetchRoleArtwork,
  saveArtworkToSell,
  saveArtworkToAuction,
  saveWallet,
  fetchWallets,
  addPaymentMethod,
  addPaymentMethodPublic,
  disablePaymentMethod,
  getPaymentMethods,
  updatePaymentMethod,
  addShippingAddress,
  editShippingAddress,
  disableShippingAddress,
  addBillingAddress,
  editBillingAddress,
  disableBillingAddress,
  loadArtworkSectionData,
  loadRoleSectionData,
  listProductsByType,
  getCart,
  addCartItem,
  updateCartItem,
  removeCartItem,
  createOrder,
  modifyOrder,
  checkout,
  getOrders,
  getOrder,
  editArtwork,
  getArtists,
  toggleArtworkVisibility,
  attachRolleableProduct,
  detachRolleableProduct,
  getRolleableProductTemperatures,
  getRolleableProductLocations,
  getRolleableProductShocks,
  getRolleableProductConfig,
  updateRolleableProductConfig,
  getAuctionsOnSale,
  getAuctionsSold,
  getAuctionsSectionData,
  getAuction,
  cancelAuction
};
