enum AdminActions {
  // action types
  LOAD_ADMIN_USERS_SECTION_DATA = 'LOAD_ADMIN_USERS_SECTION_DATA',
  LOAD_ADMIN_ARTWORKS_SECTION_DATA = 'LOAD_ADMIN_ARTWORKS_SECTION_DATA',
  LOAD_ADMIN_DISCIPLINES = 'LOAD_ADMIN_DISCIPLINES',
  LOAD_USERS = 'LOAD_USERS',
  CONFIRM_USER = 'CONFIRM_USER',
  CONFIRM_DOC_RECEIVED = 'CONFIRM_DOC_RECEIVED',
  TOGGLE_USER_VISIBILITY = 'TOGGLE_USER_VISIBILITY',

  LOAD_ADMIN_AUCTIONS_SECTION_DATA = 'LOAD_ADMIN_AUCTIONS_SECTION_DATA',
  LOAD_AUCTIONS = 'LOAD_AUCTIONS',
  LOAD_AUCTION = 'LOAD_AUCTION',
  TOGGLE_VISIBILITY = 'TOGGLE_VISIBILITY',
  TOGGLE_VISIBILITY_TIMER = 'TOGGLE_VISIBILITY_TIMER',
  TOGGLE_CLOSE_SOON = 'TOGGLE_CLOSE_SOON',
  TOGGLE_PHYSICAL_LOCATION = 'TOGGLE_PHYSICAL_LOCATION',

  LOAD_ADMIN_ARTWORK = 'LOAD_ADMIN_ARTWORK',
  LOAD_ADMIN_ARTWORKS = 'LOAD_ADMIN_ARTWORKS',

  TOGGLE_ADMIN_HIGHLIGHTED = 'SET_ADMIN_HIGHLIGHTED',

  ADD_BID_TO_AUCTION = 'ADD_BID_TO_AUCTION'
}

enum AdminMutations {
  // mutation types
  SET_ADMIN_SECTION_DATA = 'setSectionData',
  SET_ADMIN_USERS = 'SET_ADMIN_USERS',
  SET_ADMIN_USERS_TOTAL = 'SET_ADMIN_USERS_TOTAL',

  SET_ADMIN_AUCTION_SECTION_DATA = 'SET_ADMIN_AUCTION_SECTION_DATA',
  SET_ADMIN_AUCTIONS = 'SET_ADMIN_AUCTIONS',
  SET_ADMIN_AUCTION = 'SET_ADMIN_AUCTION',
  SET_ADMIN_AUCTIONS_TOTAL = 'SET_ADMIN_AUCTIONS_TOTAL',

  SET_ADMIN_ARTWORK = 'SET_ADMIN_ARTWORK',
  SET_ADMIN_ARTWORKS = 'SET_ADMIN_ARTWORKS',
  SET_ADMIN_ARTWORK_SECTION_DATA = 'SET_ARTWORK_SECTION_DATA',
  SET_ADMIN_ARTWORKS_TOTAL = 'SET_ADMIN_ARTWORKS_TOTAL',
  
  SET_ADMIN_DISCIPLINES = 'SET_ADMIN_DISCIPLINES'
}

export { AdminActions, AdminMutations };
