import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { Mutations, Actions } from '@/store/enums/StoreEnums';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'profile_es' },
    meta: {
      requiresAuth: true, // No requiere autenticación
      onlyGuest: false // Tienes que estar loggeado para entrar
    },
    component: () => import('@/layout/MainLayout.vue'),
    children: [
      {
        path: '/pendiente-confirmar',
        name: 'pendiente-confirmar',
        component: () => import('@/views/private/SigIn/PendingConfirmation.vue')
      },
      {
        path: 'perfil',
        name: 'profile_es',
        component: () => import('@/views/private/User/Profile.vue')
      },
      {
        path: 'administracion',
        redirect: { name: 'admin_users_list' },
        meta: {
          onlyAdmin: true // Sólo administradores
        },
        component: () => import('@/layout/AdminLayout.vue'),
        children: [
          {
            path: 'usuarios',
            name: 'admin_users_list',
            component: () => import('@/views/administration/User/Admin_UserList.vue')
          },
          {
            path: 'subastas',
            name: 'admin_auctions_list',
            component: () => import('@/views/administration/Auction/Admin_AuctionList.vue')
          },
          {
            path: 'artworks',
            name: 'admin_artworks_list',
            component: () => import('@/views/administration/Artworks/Admin_ArtworkList.vue')
          },
          {
            path: 'obras/:artworkHash/subasta/:auctionHash/detalle',
            name: 'admin_auctions_detail',
            component: () => import('@/views/administration/Auction/Admin_AuctionDetail.vue')
          }
        ]
      },
      {
        path: ':roleSlug',
        redirect: { name: 'editar' },
        component: () => import('@/layout/RoleLayout.vue'),
        children: [
          {
            path: 'editar',
            component: () => import('@/layout/role/RoleEditDataLayout.vue'),
            children: [
              {
                path: 'informacion-publica',
                name: 'role_edit_profile_es',
                component: () => import('@/views/private/Profile/EditProfilePublicData.vue')
              },
              {
                path: 'archivos-publicos',
                component: () => import('@/layout/role/RoleEditDocumentsDataLayout.vue'),
                children: [
                  {
                    path: '',
                    name: 'role_edit_documents_es',
                    component: () => import('@/views/private/Profile/EditFoldersPublic.vue')
                  },
                  {
                    path: ':attachmentType/archivos',
                    name: 'role_edit_files_es',
                    component: () => import('@/views/private/Profile/EditFilesPublic.vue')
                  }
                ]
              }
            ]
          },
          {
            path: '',
            component: () => import('@/layout/role/RoleSectionLayout.vue'),
            redirect: { name: 'role_dashboard_es' },
            children: [
              {
                path: 'panel-de-control',
                name: 'role_dashboard_es',
                component: () => import('@/views/private/Profile/Dashboard.vue')
              },
              {
                path: 'datos-de-perfil',
                name: 'role_profile_es',
                component: () => import('@/views/private/Profile/ProfilePublicData.vue')
              },
              {
                path: 'metodos-de-pago',
                name: 'role_payments_es',
                component: () => import('@/views/private/Profile/ProfilePaymentsData.vue')
              },
              {
                path: 'direcciones',
                name: 'role_addresses_es',
                component: () => import('@/views/private/Profile/ProfileAddressesData.vue')
              },
              {
                path: 'suscripciones',
                name: 'role_subscriptions_es',
                component: () => import('@/views/private/Profile/ProfileSubscriptionData.vue')
              }
            ]
          },
          /**
           * END - Rutas de Role
           */

          /**
           * Rutas de Artwork
           */
          {
            path: 'obras',
            name: 'artwork_dashboard_es',
            component: () => import('@/views/private/Artwork/ArtworkList.vue')
          },
          {
            path: 'obras/crear',
            name: 'artwork_create_es',
            component: () => import('@/views/private/Artwork/ArtworkCreate.vue')
          },
          {
            path: 'obras/:artworkHash/:artworkSlug/detalle',
            name: 'artwork_detail_es',
            component: () => import('@/views/private/Artwork/ArtworkDetail.vue')
          },
          {
            path: 'obras/:artworkHash/:artworkSlug/editar',
            name: 'artwork_edit_es',
            component: () => import('@/views/private/Artwork/ArtworkEdit.vue')
          },

          /**
           * END - Rutas de Artwork
           */

          /**
           * Rutas de Ecommerce
           */
          {
            path: 'e-commerce',
            name: 'ecommerce_dashboard',
            component: () => import('@/views/private/Ecommerce/eDashboard.vue')
          },
          {
            path: 'e-commerce/orders',
            name: 'ecommerce_orders',
            component: () => import('@/views/private/Ecommerce/eOrders.vue')
          },
          {
            path: 'e-commerce/process-order',
            name: 'ecommerce_order_select_addresses',
            component: () => import('@/views/private/Ecommerce/eOrderSelectAddresses.vue')
          },
          {
            path: 'e-commerce/:orderHash/pay',
            name: 'ecommerce_order_pay',
            component: () => import('@/views/private/Ecommerce/eOrderPay.vue')
          },
          {
            path: 'e-commerce/:orderHash/order-complete',
            name: 'ecommerce_order_complete',
            component: () => import('@/views/private/Ecommerce/eOrderComplete.vue')
          },

          {
            path: 'devices',
            name: 'devices_dashboard',
            component: () => import('@/views/private/Device/DeviceList.vue')
          },

          {
            path: 'devices/:deviceHash',
            name: 'devices_detail',
            component: () => import('@/views/private/Device/DeviceDetail.vue'),
            children: [
              {
                path: 'devices/:deviceHash/dashboard',
                name: 'devices_detail_dashboard',
                component: () => import('@/views/private/Device/DeviceDetailDashboard.vue')
              },
              {
                path: 'devices/:deviceHash/config',
                name: 'devices_detail_config',
                component: () => import('@/views/private/Device/DeviceDetailConfig.vue')
              },
              {
                path: 'devices/:deviceHash/locations',
                name: 'devices_detail_locations',
                component: () => import('@/views/private/Device/DeviceDetailLocations.vue')
              },
              {
                path: 'devices/:deviceHash/temperatures',
                name: 'devices_detail_temperatures',
                component: () => import('@/views/private/Device/DeviceDetailTemperatures.vue')
              },
              {
                path: 'devices/:deviceHash/shocks',
                name: 'devices_detail_shocks',
                component: () => import('@/views/private/Device/DeviceDetailShocks.vue')
              }
            ]
          },
          {
            path: 'auctions/on-sale',
            name: 'auctions_list',
            component: () => import('@/views/private/Auction/AuctionList.vue')
          },
          {
            path: 'auctions/sold',
            name: 'auctions_list_sold',
            component: () => import('@/views/private/Auction/AuctionListSold.vue')
          },
          {
            path: 'auctions/:auctionHash',
            name: 'auctions_detail',
            component: () => import('@/views/private/Auction/AuctionDetail.vue')
          }
          // {
          //   path: '',
          //   component: () => import('@/layout/MainLayout.vue'),
          //   children: [
          //     {
          //       path: 'productos',
          //       name: 'ecommerce_dashboard',
          //       component: () => import('@/views/private/Ecommerce/eDashboard.vue')
          //     },
          //     {
          //       path: 'mis-pedidos',
          //       name: 'ecommerce_orders',
          //       component: () => import('@/views/private/Ecommerce/eOrders.vue')
          //     },
          //     {
          //       path: 'tramitar',
          //       name: 'ecommerce_order_select_addresses',
          //       component: () => import('@/views/private/Ecommerce/eOrderProcess.vue')
          //     },
          //     {
          //       path: 'order-complete',
          //       name: 'ecommerce_order_complete_es',
          //       component: () => import('@/views/private/Ecommerce/eOrderComplete.vue')
          //     }
          //   ]
          // }
          /**
           * END - Rutas de Ecommerce
           */
        ]
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layout/Auth.vue'),
    meta: {
      requiresAuth: false, // No requiere autenticación
      onlyGuest: true // No tienes que estar loggeado para entrar
    },
    children: [
      {
        path: '/iniciar-sesion',
        name: 'sign-in',
        component: () => import('@/views/authentication/SignIn.vue')
      },
      {
        path: '/registrarse',
        name: 'sign-up',
        component: () => import('@/views/authentication/SignUp.vue')
      },
      {
        path: '/reiniciar-contrasena',
        name: 'password-reset',
        component: () => import('@/views/authentication/PasswordReset.vue')
      },
      {
        path: '/confirm-password',
        name: 'confirm-password',
        component: () => import('@/views/authentication/ConfirmPassword.vue')
      }
    ]
  },
  {
    path: '/proceso-de-registro',
    name: 'multi-step-auth',
    meta: {
      requiresAuth: true, // Requiere autenticación
      onlyGuest: false // Accesible si no estás logeado
    },
    component: () => import('@/views/authentication/MultiStepAuth.vue')
  },
  {
    path: '/artist-form',
    name: 'typeform',
    meta: {
      requiresAuth: false, // No requiere autenticación
      onlyGuest: false // No tienes que estar loggeado para entrar
    },
    component: () => import('@/views/public/SohoForm.vue')
  },

  {
    path: '/500',
    name: '500',
    meta: {
      requiresAuth: false, // No requiere autenticación
      onlyGuest: false // No tienes que estar loggeado para entrar
    },
    component: () => import('@/views/common/Error500.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      requiresAuth: false, // No requiere autenticación
      onlyGuest: false // No tienes que estar loggeado para entrar
    },
    component: () => import('@/views/common/Error404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  await store.dispatch(Actions.VERIFY_AUTH);

  const isUserAuthenticated = store.getters.isUserAuthenticated;
  let user, isUserPending, currentRole;

  if (isUserAuthenticated) {
    await store.dispatch(Actions.LOAD_CART);
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (to.matched.some((routes) => routes.meta.onlyAdmin)) {
    if (isUserAuthenticated) {
      user = store.getters.currentUser;
      if (user.is_admin !== true) {
        next({ name: '404' });
        return;
      } else {
        next();
      }
    } else {
      next({ name: 'sign-in' });
      return;
    }
  } else if (to.matched.some((routes) => routes.meta.requiresAuth)) {
    if (isUserAuthenticated) {
      user = store.getters.currentUser;

      if (to.params.roleSlug) {
        if (to.params.roleSlug !== store.getters.currentRole.slug) {
          next({ name: '404' });
          return;
        }
      }

      if (user.is_admin === 1) {
        next();
        // Todavía no ha finalizado el registro.
      } else if (user.status === 'draft' || user.status === 'pending') {
        if (to.name === 'multi-step-auth') {
          next();
        } else {
          next({ name: 'multi-step-auth' });
          return;
        }
        // El registro ya se ha finalizado
      } else {
        if (user.has_buyers) {
          // window.location.href = process.env.VUE_APP_PUBLIC_URL + '/';

          const userLang = navigator.language || navigator['userLanguage'];
          const lang = userLang.split('-')[0];

          if (lang === 'es') {
            window.location.href = process.env.VUE_APP_PUBLIC_URL + '/es/mi-perfil';
          } else if (lang == 'en') {
            window.location.href = process.env.VUE_APP_PUBLIC_URL + '/en/my-profile';
          } else {
            window.location.href = process.env.VUE_APP_PUBLIC_URL + '/es/mi-perfil';
          }
          next(false);
          return;
        } else {
          if (user.has_museums || user.has_artists) {
            isUserPending = store.getters.isUserPending;

            currentRole = store.getters.currentRole;
            if (isUserPending) {
              if (to.name == 'pendiente-confirmar') {
                next();
              }
              next({ name: 'pendiente-confirmar' });
              return;
            } else {
              if (to.name == 'pendiente-confirmar') {
                next({ name: 'role_dashboard_es', params: { roleSlug: currentRole.slug } });
                return;
              } else {
                next();
              }
            }
          }
        }
      }
    } else {
      next({ name: 'sign-in' });
      return;
    }
  } else if (to.matched.some((record) => record.meta.onlyGuest)) {
    if (isUserAuthenticated) {
      user = store.getters.currentUser;

      if (user.is_admin === true) {
        next({ name: 'admin_users_list' });
        return;
      } else if (user.status === 'draft' || user.status === 'pending') {
        if (to.name === 'multi-step-auth') {
          next();
        } else {
          next({ name: 'multi-step-auth' });
          return;
        }
        // El registro ya se ha finalizado
      } else if (user.has_buyers) {
        const userLang = navigator.language || navigator['userLanguage'];
        const lang = userLang.split('-')[0];

        if (lang === 'es') {
          window.location.href = process.env.VUE_APP_PUBLIC_URL + '/es/mi-perfil';
        } else if (lang == 'en') {
          window.location.href = process.env.VUE_APP_PUBLIC_URL + '/en/my-profile';
        } else {
          window.location.href = process.env.VUE_APP_PUBLIC_URL + '/es/mi-perfil';
        }
        next(false);
        return;
      } else {
        currentRole = store.getters.currentRole;
        next({ name: 'role_dashboard_es', params: { roleSlug: currentRole.slug } });
        return;
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
