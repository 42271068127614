import {Product, ProductPrice} from '@/types/interfaces/global/MarketInterface';
import {RoleableDevice} from "@/types/interfaces/global/Iot";

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
    isPending: boolean;
}

export default class CurrentUser implements UserAuthInfo {
    errors = {};
    user = {} as User;
    isAuthenticated = false;
    isPending = false;
}

export interface Role {
    has_public_profile: boolean;
    contact_email: string;
    hash: string;
    status: string;
    name: string;
    surname: string;
    full_name: string;
    phone: string;
    is_owner: boolean;
    has_documentation_received: boolean;
    slug: string;
    biography: string;
    biography_2: string;
    biography_3: string;
    biography_4: string;
    biography_phrase: string;
    caption: string;
    description: string;
    billing_addresses: BillingAddress[];
    shipping_addresses: ShippingAddress[];
    payment_methods_crypto: PaymentMethod[];
    payment_methods_fiat: PaymentMethod[];
    plan_subscription: Subscription;
    configurations: Configurations;
    order_publics: [];
    images: Image[];
    roleable_devices: RoleableDevice[];
    roleable_devices_inactive: RoleableDevice[];
}

export interface Image {
    hash: string;
    order: number;
    type: string;
    uri: string;
    thumbnails: Thumbnail[];
}

export interface Thumbnail {
    hash: string;
    aspect_radio: number;
    size: string;
    uri: string;
}

export interface User {
    name: string;
    email: string;
    email_verified: boolean;
    has_artists: boolean;
    has_buyers: boolean;
    has_museums: boolean;
    hash: string;
    is_admin: boolean;
    active: boolean;
    status: string;
    roles: [Role | undefined];
    orders: Orders[];
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
    isPending: boolean;
}

export interface PaymentMethod {
    hash: string;
    is_default: boolean;
    pm_last_four: string;
    pm_type: string;
    type: string;
    wallet: Wallet | undefined;
}

export interface PaymentMethodRequest {
    is_default: boolean;
    payment_source_id: string;
    name: string;
    type: string;
}

export interface Wallet {
    address: string;
}

export interface ShippingAddress {
    hash: string;
    additional_info: string;
    address: string;
    city: string;
    region: Region | undefined;
    country: Country;
    is_default: boolean;
    name: string;
    phone: string;
    postal_code: string;
}

export interface ShippingAddressRequest {
    hash: string | undefined; // Si está undefined es que es NUEVO
    additional_info: string;
    address: string;
    city: string;
    region_id: number;
    country_id: number;
    is_default: boolean;
    name: string;
    phone: string;
    postal_code: string;
}

export interface BillingAddress {
    hash: string;
    business_name: string;
    city: string;
    region: Region | undefined;
    country: Country;
    postal_code: string;
    is_default: boolean;
    email: string;
    name: string;
    surname: string;
    phone: string;
    type: string;
    address: string;
    nif: string;
}

export interface BillingAddressRequest {
    hash: string;
    business_name: string;
    city: string;
    region_id: number;
    country_id: number;
    postal_code: string;
    is_default: boolean;
    email: string;
    name: string;
    surname: string;
    phone: string;
    type: string;
    address: string;
    nif: string;
}

export interface Country {
    id: number;
    hash: string;
    name: string;
    slug: string;
    regions: [Region | undefined];
}

export interface Region {
    id: number;
    hash: string;
    name: string;
    slug: string;
    postal_code: string;
}

export interface Subscription {
    name: string;
    hash: string;
    stripe_status: string;
    created_at: string;
    renew_at: string;
    product: Product;
    price: ProductPrice;
}

export interface Configurations {
    allowed_sells: Record<string, unknown>;
    available_currencies: [];
    fees: { auction_fee: number; iva: number; marketplace_fee: number };
    model_limits: ModelLimits;
}

export interface ModelLimits {
    ArtistUsers: number;
    BillingAddress: number;
    BuyerUsers: number;
    MuseumUsers: number;
    PaymentMethods: number;
    ShippingAddress: number;
}

export interface SectionData {
    current_artworks: number;
    current_artworks_limit: number;
}

export interface TotalSoldData {
    amount_artwork_sold: number;
    amount_artwork_auction: number;
    amount_sold: number;
}

export interface Orders {
    amount: number;
    taxes: number;
    total: number;
    shipping_amount: number;
    shipping_taxes: number;
    shipping_total: number;
    unit_amount: number;
    unit_taxes: number;
    subscription_amount: number;
    subscription_taxes: number;
    status: string;
    public_identifier: string;
}

export enum SUBSCRIPTION_STATUS {
    'trialing' = 'trialing',
    'active' = 'active',
    'incomplete' = 'incomplete',
    'incomplete_expired' = 'incomplete_expired',
    'past_due' = 'past_due',
    'canceled' = 'canceled',
    'unpaid' = 'unpaid',
}