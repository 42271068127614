import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Cart, CartInfo, CartItem, Order } from '@/types/interfaces/global/MarketInterface';
import sanctum from '@/core/plugins/sanctum';
import store from '@/store';

@Module
export default class CartModule extends VuexModule implements CartInfo {
  errors = {};
  cart = {} as Cart;
  order = null as Order | null;

  get getCartItems(): CartItem[] {
    return this.cart.items;
  }

  get getCart(): Cart {
    return this.cart;
  }

  get getCartErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_CART_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_CART](cart: Cart) {
    this.cart = cart;
  }

  @Mutation
  [Mutations.ADD_CART_ITEM](cartItem) {
    this.cart.items.push(cartItem);
  }

  @Mutation
  [Mutations.REMOVE_CART_ITEM](itemId) {
    const index = this.cart.items.findIndex((obj) => obj.id === itemId);
    this.cart.items.splice(index, 1);
  }

  @Action({ rawError: true })
  async [Actions.ADD_CART_ITEM]({ productGroup, quantity }) {
    const cart = await sanctum.addCartItem(productGroup, quantity);
    this.context.commit(Mutations.SET_CART, cart);
  }

  @Action
  async [Actions.UPDATE_CART_ITEM](cartItem: CartItem) {
    try {
      console.log(cartItem);
      const cart = await sanctum.updateCartItem(cartItem);

      this.context.commit(Mutations.SET_CART, cart);
    } catch (e: any) {
      this.context.commit(Mutations.SET_CART_ERROR, e.response.data.errors);
    }
  }

  @Action
  async [Actions.REMOVE_CART_ITEM](cartItem: CartItem) {
    try {
      const cart = await sanctum.removeCartItem(cartItem);
      console.log(cart);
      this.context.commit(Mutations.SET_CART, cart);
    } catch (e: any) {
      this.context.commit(Mutations.SET_CART_ERROR, e.response.data.errors);
    }
  }

  @Action
  async [Actions.LOAD_CART]() {
    try {
      const currentRole = store.getters.currentRole;
      if (Object.keys(currentRole).length !== 0) {
        const cart = await sanctum.getCart(currentRole);
        this.context.commit(Mutations.SET_CART, cart);
      }
    } catch (e: any) {
      this.context.commit(Mutations.SET_CART_ERROR, e.response.data.errors);
    }
  }
}
